import { Device } from 'framework/api/models/device-models';
import { ItemList } from 'framework/api/models';
import { validateDevice } from './get-device';

export const validateGetDevices = (devicesResponseDTO: any): ItemList<Device> => {
    return {
        items: 'items' in devicesResponseDTO ? validateDevices(devicesResponseDTO.items) : [],
        count: 'count' in devicesResponseDTO ? devicesResponseDTO.count || 0 : 0
    };
};

const validateDevices = (devicesDTO: any[]): Device[] => {
    const devices: Device[] = [];

    devicesDTO.forEach((deviceDTO: any) => {
        const device = validateDevice(deviceDTO);
        if (device) {
            devices.push(device);
        }
    });

    return devices;
};
