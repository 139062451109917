import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material';

export const useStyles = makeStyles()((theme: Theme) => ({
    button: {
        padding: 14,
        '& .MuiButton-endIcon': {
            position: 'absolute',
            right: '1em'
        },
        '& .MuiButton-startIcon': {
            position: 'absolute',
            left: '1em'
        }
    }
}));
