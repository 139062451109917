import { RoutesType } from './index';
import { RouteN } from '../../routes-path';
import { lazy } from 'react';
import { Outlet } from 'react-router-dom';
import { PERMISSIONS } from 'framework/utils';

const Config = lazy(() => import('src/features/config/framework/config'));
const TagManagementEdit = lazy(
    () => import('src/features/config/framework/tag-management/edit/tag-management-edit')
);
const TagManagementCreate = lazy(
    () => import('src/features/config/framework/tag-management/create/tag-management-create')
);

export const ConfigRoutes: RoutesType[] = [
    {
        path: RouteN.config.route,
        suspense: false,
        element: <Outlet />,
        id: 'config',
        children: [
            {
                path: '',
                suspense: true,
                element: <Config />,
                id: 'config-0'
            },
            {
                path: RouteN.config.children.tagManagement.route,
                suspense: false,
                element: <Outlet />,
                permission: PERMISSIONS.DEVICE_MANAGEMENT_VIEW,
                id: 'tag-management-0',
                children: [
                    {
                        path: RouteN.config.children.tagManagement.children.edit,
                        suspense: true,
                        element: <TagManagementEdit />,
                        permission: PERMISSIONS.DEVICE_MANAGEMENT_VIEW,
                        id: 'tag-management-1'
                    },
                    {
                        path: RouteN.config.children.tagManagement.children.create,
                        suspense: true,
                        element: <TagManagementCreate />,
                        permission: PERMISSIONS.DEVICE_MANAGEMENT_CREATE,
                        id: 'tag-management-2'
                    }
                ]
            }
        ]
    }
];
