import { useI18nService } from 'framework/i18n/i18n-service';
import { useMyApi } from 'framework/api/api-provider';
import { useNotificationService } from 'framework/notifications/notifications-service';
import { useQuery } from '@tanstack/react-query';
import { alertsKey, alertsKeys } from 'framework/api/hooks';
import { PaginatedProps } from 'framework/api/models';
import { buildPaginatedParams } from 'framework/utils/url';

export function useQueryGetAlerts<TQuery, TSort>(queryProps: PaginatedProps<TQuery, TSort>) {
    const { t } = useI18nService();
    const api = useMyApi();
    const notification = useNotificationService();

    const paramsURL = buildPaginatedParams(queryProps);

    const { isLoading, data, isError } = useQuery(
        [...alertsKey(), paramsURL],
        () => api.alert().getAlerts(paramsURL),
        {
            keepPreviousData: true,
            onError: () => {
                notification(t('internalError'), {
                    variant: 'error',
                    preventDuplicate: true,
                    key: alertsKeys.index
                });
            }
        }
    );

    return {
        isLoading,
        alerts: data && data.items,
        count: data && data.count ? data.count : 0,
        isError
    };
}
