import { Tag } from 'framework/api/models/tag-models';
import { ItemList } from 'framework/api/models';
import { validateTag } from './get-tag';

export const validateGetTags = (tagsResponseDTO: any): ItemList<Tag> => {
    return {
        items: 'items' in tagsResponseDTO ? validateTags(tagsResponseDTO.items) : [],
        count: 'count' in tagsResponseDTO ? tagsResponseDTO.count || 0 : 0
    };
};

export const validateTags = (tagsDTO: any[]): Tag[] => {
    const tags: Tag[] = [];

    tagsDTO.forEach((tagDTO: any) => {
        const tag = validateTag(tagDTO);
        if (tag) {
            tags.push(tag);
        }
    });

    return tags;
};
