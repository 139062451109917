import { MyApiRequest } from 'framework/api/my-api';
import { CreateDeviceBody, Device, EditDeviceBody } from 'framework/api/models/device-models';
import { ItemList } from 'framework/api/models';
import {
    validateDeviceDataValues,
    validateDeviceDataUnits,
    validateGetDeviceDetail,
    validateGetDevices
} from 'framework/api/validations/device';
import { DeviceDataValues } from 'framework/api/models/device-models/device';

const DEVICE_INDEX = 'devices';
const DEVICE_DATA = 'data';

export function getDevice(request: MyApiRequest) {
    async function getDevices(paramsURL: string): Promise<ItemList<Device>> {
        const response = await request(DEVICE_INDEX + paramsURL, 'GET');

        const data = await response.json();

        return validateGetDevices(data);
    }

    async function getDeviceDataUnits(deviceId: string): Promise<string[]> {
        const response = await request(`${DEVICE_INDEX}/${deviceId}/${DEVICE_DATA}/units`, 'GET');

        const data = await response.json();

        return validateDeviceDataUnits(data);
    }

    async function getDeviceDataValues(
        deviceId: string,
        paramsURL: string
    ): Promise<DeviceDataValues[]> {
        const response = await request(
            `${DEVICE_INDEX}/${deviceId}/${DEVICE_DATA}/values${paramsURL}`,
            'GET'
        );

        const data = await response.json();

        return validateDeviceDataValues(data);
    }

    async function postDevice(requestBody: CreateDeviceBody): Promise<boolean> {
        const response = await request(DEVICE_INDEX, 'POST', requestBody);
        return response.ok;
    }

    async function getDevice(deviceId: string): Promise<Device | undefined> {
        const response = await request(DEVICE_INDEX + '/' + deviceId, 'GET');

        const data = await response.json();

        return validateGetDeviceDetail(data);
    }

    async function putDevice(requestBody: EditDeviceBody): Promise<boolean> {
        const response = await request(DEVICE_INDEX, 'PUT', requestBody);

        return response.ok;
    }

    async function deleteDevice(deviceId: string): Promise<boolean> {
        const response = await request(DEVICE_INDEX + '/' + deviceId, 'DELETE');

        return response.ok;
    }

    return {
        getDevices,
        getDevice,
        getDeviceDataUnits,
        getDeviceDataValues,
        postDevice,
        putDevice,
        deleteDevice
    };
}
