const Alerts = (props: { size?: number; color?: string }) => {
    return (
        <svg
            height={props.size || 24}
            viewBox='0 0 24 24'
            width={props.size || 24}
            version='1.1'
            xmlns='http://www.w3.org/2000/svg'
        >
            <title>00 Tokens/Icons/alert/default</title>
            <g id='Symbols' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                <g id='Group'>
                    <rect id='spacing' x='0' y='0' width='24' height='24'></rect>
                    <path
                        d='M12,21.5 C13.1,21.5 14,20.6 14,19.5 L10,19.5 C10,20.6 10.9,21.5 12,21.5 Z M18,15.5 L18,10.5 C18,7.43 16.37,4.86 13.5,4.18 L13.5,3.5 C13.5,2.67 12.83,2 12,2 C11.17,2 10.5,2.67 10.5,3.5 L10.5,4.18 C7.64,4.86 6,7.42 6,10.5 L6,15.5 L4,17.5 L4,18.5 L20,18.5 L20,17.5 L18,15.5 Z M16,16.5 L8,16.5 L8,10.5 C8,8.02 9.51,6 12,6 C14.49,6 16,8.02 16,10.5 L16,16.5 Z'
                        id='Shape'
                        fill={props.color ? props.color : '#0C0C0C'}
                        fillRule='nonzero'
                    ></path>
                </g>
            </g>
        </svg>
    );
};

export default Alerts;
