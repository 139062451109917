import { useI18nService } from 'framework/i18n/i18n-service';
import { useMyApi } from 'framework/api/api-provider';
import { useNotificationService } from 'framework/notifications/notifications-service';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { alertsByIdKey, alertsKey, alertsKeys } from 'framework/api/hooks';

export function useMutationMarkReadAlert() {
    const { t } = useI18nService();
    const api = useMyApi();
    const notification = useNotificationService();
    const queryClient = useQueryClient();

    const {
        mutate,
        data: response,
        isLoading,
        isError
    } = useMutation(api.alert().readAlerts, {
        onSuccess: async (res, req) => {
            queryClient.invalidateQueries({ queryKey: alertsKey() });
            if (!!req && !!req.idToRead) {
                await queryClient.invalidateQueries({ queryKey: alertsByIdKey(req.idToRead) });
            }
        },
        onError: () => {
            notification(t('internalError'), {
                variant: 'error',
                preventDuplicate: true,
                key: alertsKeys.alerts
            });
        }
    });

    return {
        mutate,
        response,
        isError,
        isLoading
    };
}
