import { MyApiRequest } from 'framework/api/my-api';
import { ItemList } from 'framework/api/models';
import { CreateScriptBody, EditScriptBody, Script } from 'framework/api/models/script-models';
import { validateGetScriptDetail, validateGetScripts } from 'framework/api/validations/scripts';

const SCRIPTS_INDEX = 'scripts/';
const SCRIPT_INDEX = 'script';
const DEVICES_INDEX = 'devices/';

export function getScripts(request: MyApiRequest) {
    async function getScripts(paramsURL: string, deviceId: string): Promise<ItemList<Script>> {
        const response = await request(
            DEVICES_INDEX + deviceId + '/' + 'scripts' + paramsURL,
            'GET'
        );

        const data = await response.json();

        return validateGetScripts(data);
    }

    async function getScript(id: string): Promise<Script | undefined> {
        const response = await request(DEVICES_INDEX + SCRIPT_INDEX + '/' + id, 'GET');

        const data = await response.json();

        return validateGetScriptDetail(data);
    }

    async function postScript(requestBody: CreateScriptBody): Promise<boolean> {
        const response = await request(DEVICES_INDEX + SCRIPT_INDEX, 'POST', requestBody);

        return response.ok;
    }

    async function putScript(requestBody: EditScriptBody): Promise<boolean> {
        const response = await request(DEVICES_INDEX + SCRIPT_INDEX, 'PUT', requestBody);

        return response.ok;
    }

    async function deleteScript(idScript: string): Promise<boolean> {
        const response = await request(DEVICES_INDEX + SCRIPT_INDEX + '/' + idScript, 'DELETE');

        return response.ok;
    }

    return {
        getScripts,
        getScript,
        postScript,
        putScript,
        deleteScript
    };
}
