import { theme } from './theme';
import { phoneMultiplierSmall } from './multipliers';

export const appTypographys = {
    h4: theme.typography.h4,
    h5: theme.typography.h5,
    h6: theme.typography.h6,
    subtitle1: theme.typography.subtitle1,
    subtitle2: theme.typography.subtitle2,
    body1: theme.typography.body1,
    body2: theme.typography.body2,
    overline: theme.typography.overline,
    h1: {
        fontFamily: theme.typography.h1.fontFamily,
        fontSize: theme.typography.h1.fontSize + 'rem',
        fontWeight: theme.typography.h1.fontWeight,
        color: theme.palette.text.primary,
        lineHeight: theme.typography.h1.lineHeight,
        [theme.breakpoints.down('sm')]: {
            fontSize: Number(theme.typography.h1.fontSize) * phoneMultiplierSmall + 'rem'
        }
    },
    headerTitle: {
        fontFamily: theme.typography.headerTitle.fontFamily,
        fontSize: theme.typography.headerTitle.fontSize + 'rem',
        fontWeight: theme.typography.headerTitle.fontWeight,
        color: theme.palette.text.primary,
        lineHeight: theme.typography.headerTitle.lineHeight,
        [theme.breakpoints.down('sm')]: {
            fontSize: Number(theme.typography.headerTitle.fontSize) * phoneMultiplierSmall + 'rem'
        }
    },
    contentTable: {
        fontFamily: theme.typography.contentTable.fontFamily,
        fontSize: theme.typography.contentTable.fontSize + 'rem',
        fontWeight: theme.typography.contentTable.fontWeight,
        lineHeight: theme.typography.contentTable.lineHeight,
        color: theme.palette.text.primary,
        [theme.breakpoints.down('sm')]: {
            fontSize: Number(theme.typography.contentTable.fontSize) * phoneMultiplierSmall + 'rem'
        }
    },
    inputActiveFilled: {
        fontFamily: theme.typography.inputActiveFilled.fontFamily,
        fontSize: theme.typography.inputActiveFilled.fontSize + 'rem',
        fontWeight: theme.typography.inputActiveFilled.fontWeight,
        lineHeight: theme.typography.inputActiveFilled.lineHeight,
        color: theme.palette.text.primary,
        [theme.breakpoints.down('sm')]: {
            fontSize:
                Number(theme.typography.inputActiveFilled.fontSize) * phoneMultiplierSmall + 'rem'
        }
    },
    inputInactive: {
        fontFamily: theme.typography.inputInactive.fontFamily,
        fontSize: theme.typography.inputInactive.fontSize + 'rem',
        fontWeight: theme.typography.inputInactive.fontWeight,
        color: theme.palette.common.gray86,
        lineHeight: theme.typography.inputInactive.lineHeight,
        [theme.breakpoints.down('sm')]: {
            fontSize: Number(theme.typography.inputInactive.fontSize) * phoneMultiplierSmall + 'rem'
        }
    },
    sectionTitle: {
        fontFamily: theme.typography.sectionTitle.fontFamily,
        fontSize: theme.typography.sectionTitle.fontSize + 'rem',
        fontWeight: theme.typography.sectionTitle.fontWeight,
        color: theme.palette.text.primary,
        lineHeight: theme.typography.sectionTitle.lineHeight,
        [theme.breakpoints.down('sm')]: {
            fontSize: Number(theme.typography.sectionTitle.fontSize) * phoneMultiplierSmall + 'rem'
        }
    },
    headerTable: {
        fontFamily: theme.typography.headerTable.fontFamily,
        fontSize: theme.typography.headerTable.fontSize + 'rem',
        fontWeight: theme.typography.headerTable.fontWeight,
        color: theme.palette.text.primary,
        lineHeight: theme.typography.headerTable.lineHeight,
        [theme.breakpoints.down('sm')]: {
            fontSize: Number(theme.typography.headerTable.fontSize) * phoneMultiplierSmall + 'rem'
        }
    },
    description: {
        fontFamily: theme.typography.description.fontFamily,
        fontSize: theme.typography.description.fontSize + 'rem',
        fontWeight: theme.typography.description.fontWeight,
        color: theme.palette.text.primary,
        lineHeight: theme.typography.description.lineHeight,
        [theme.breakpoints.down('sm')]: {
            fontSize: Number(theme.typography.description.fontSize) * phoneMultiplierSmall + 'rem'
        }
    },
    body: {
        fontFamily: theme.typography.body.fontFamily,
        fontSize: theme.typography.body.fontSize + 'rem',
        fontWeight: theme.typography.body.fontWeight,
        color: theme.palette.text.primary,
        [theme.breakpoints.down('sm')]: {
            fontSize: Number(theme.typography.body.fontSize) * phoneMultiplierSmall + 'rem'
        }
    },
    button: {
        fontFamily: theme.typography.button.fontFamily,
        fontSize: theme.typography.button.fontSize + 'rem',
        fontWeight: theme.typography.button.fontWeight,
        color: theme.palette.text.primary,
        [theme.breakpoints.down('sm')]: {
            fontSize: Number(theme.typography.button.fontSize) * phoneMultiplierSmall + 'rem'
        }
    },
    deviceInfo: {
        fontFamily: theme.typography.deviceInfo.fontFamily,
        fontSize: theme.typography.deviceInfo.fontSize + 'rem',
        fontWeight: theme.typography.deviceInfo.fontWeight,
        lineHeight: theme.typography.deviceInfo.lineHeight,
        color: theme.palette.text.primary,
        [theme.breakpoints.down('sm')]: {
            fontSize: Number(theme.typography.deviceInfo.fontSize) * phoneMultiplierSmall + 'rem'
        }
    },
    caption: {
        fontFamily: theme.typography.caption.fontFamily,
        fontSize: theme.typography.caption.fontSize + 'rem',
        fontWeight: theme.typography.caption.fontWeight,
        color: theme.palette.text.primary
    }
};
