import './index.css';
import App from 'framework/web';
import 'framework/i18n';
import { createRoot } from 'react-dom/client';
import { StrictMode } from 'react';

const container = document.getElementById('root') as HTMLDivElement;
const root = createRoot(container);

root.render(
    <StrictMode>
        <App />
    </StrictMode>
);
