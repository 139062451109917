import { MyApiRequest } from 'framework/api/my-api';
import { CreateUserBody, EditUserBody, Me, User } from 'framework/api/models/user-models';
import { ItemList } from 'framework/api/models';
import { validateGetUserDetail, validateGetUsers } from 'framework/api/validations/user';
import { validateGetMe } from 'framework/api/validations/user/get-me';
import { EditUserMeBody } from 'framework/api/models/user-models/user';

const USER_INDEX = 'users';

export function getUser(request: MyApiRequest) {
    async function getUsers(paramsURL: string): Promise<ItemList<User>> {
        const response = await request(USER_INDEX + paramsURL, 'GET');

        const data = await response.json();

        return validateGetUsers(data);
    }

    async function getMe(): Promise<Me> {
        const response = await request(USER_INDEX + '/me', 'GET');

        const data = await response.json();

        return validateGetMe(data);
    }

    async function getUser(id: string): Promise<User | undefined> {
        const response = await request(USER_INDEX + '/' + id, 'GET');

        const data = await response.json();

        return validateGetUserDetail(data);
    }

    async function postUser(requestBody: CreateUserBody): Promise<boolean> {
        const response = await request(USER_INDEX, 'POST', requestBody);

        return response.ok;
    }

    async function putUser(requestBody: EditUserBody): Promise<boolean> {
        const response = await request(USER_INDEX, 'PUT', requestBody);

        return response.ok;
    }

    async function putMe(requestBody: EditUserMeBody): Promise<boolean> {
        const response = await request(USER_INDEX + '/me', 'PUT', requestBody);

        return response.ok;
    }

    async function deleteUser(idUser: string): Promise<boolean> {
        const response = await request(USER_INDEX + '/' + idUser, 'DELETE');

        return response.ok;
    }

    return {
        getUsers,
        getUser,
        postUser,
        putUser,
        putMe,
        deleteUser,
        getMe
    };
}
