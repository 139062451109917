import { RouteN } from 'routing/routes-path';
import { Outlet } from 'react-router-dom';
import React, { lazy, ReactElement } from 'react';
import { UserRoutes } from './user-routes';
import { DevicesRoutes } from './devices-routes';
import { ConfigRoutes } from './config-routes';
import { PERMISSIONS } from 'framework/utils';
import AuthenticationGuard from 'routing/framework/guards/authentication-guard';
import ProtectedAuthRoutes from 'routing/framework/guards/protected-auth-routes';
import { AlertRoutes } from './alert-routes';

const AppLayout = lazy(() => import('src/framework/web/layout/app-layout'));
const ResetPassword = lazy(
    () => import('src/features/auth/framework/reset-password/reset-password')
);
const Login = lazy(() => import('src/features/auth/framework/login/login'));
const NewPassword = lazy(() => import('src/features/auth/framework/new-password/new-password'));
const Terms = lazy(() => import('src/features/components/terms/terms-of-service'));
const Privacy = lazy(() => import('src/features/components/privacy/privacy'));
const NotFoundPage = lazy(() => import('src/framework/web/components/404/not-found-page'));

export type RoutesType = {
    path: string;
    suspense: boolean;
    element: ReactElement;
    id: string;
    permission?: PERMISSIONS;
    children?: RoutesType[];
};

const routes: RoutesType[] = [
    {
        path: RouteN.auth.route,
        suspense: false,
        element: (
            <ProtectedAuthRoutes>
                <Outlet />
            </ProtectedAuthRoutes>
        ),
        id: 'auth',
        children: [
            {
                path: RouteN.auth.children.login,
                suspense: true,
                element: <Login />,
                id: 'auth-0'
            },
            {
                path: RouteN.auth.children.reset,
                suspense: true,
                element: <ResetPassword />,
                id: 'auth-1'
            },
            {
                path: RouteN.auth.children.newPassword,
                suspense: true,
                element: <NewPassword />,
                id: 'auth-2'
            }
        ]
    },
    {
        path: RouteN.terms.route,
        suspense: true,
        element: <Terms />,
        id: 'terms'
    },
    {
        path: RouteN.privacy.route,
        suspense: true,
        element: <Privacy />,
        id: 'privacy'
    },
    {
        path: '/',
        suspense: true,
        element: (
            <AuthenticationGuard>
                <AppLayout />
            </AuthenticationGuard>
        ),
        id: 'layout',
        children: [
            {
                path: '',
                id: 'route-index',
                suspense: false,
                element: <></>
            },
            ...UserRoutes,
            ...DevicesRoutes,
            ...ConfigRoutes,
            ...AlertRoutes
        ]
    },
    {
        path: '*',
        suspense: true,
        element: <NotFoundPage />,
        id: '404'
    }
];

export default routes;
