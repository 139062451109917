import * as React from 'react';
import { createContext, useContext, useState } from 'react';
import { Me } from 'framework/api/models/user-models';
import { hasPermissions } from 'features/auth/framework/provider/has-permissions';
import { ROLES } from 'framework/utils';

type AuthContext = {
    authState: [Me, (me: Me) => void];
    hasPermissions: ReturnType<typeof hasPermissions>;
};

export const defaultAuth: Me = {
    id: '',
    email: '',
    name: '',
    role: 0 as ROLES,
    permissions: []
};

export const testAuth: Me = {
    id: '60feb879-51d7-426b-8165-6fe5754e34e3',
    email: 'admin@digio.es',
    name: 'Admin',
    role: 1,
    permissions: [1, 2, 3, 4, 11, 12, 13, 14, 21, 22]
};

const defaultContext: AuthContext = {
    authState: [defaultAuth, () => undefined],
    hasPermissions: hasPermissions([])
};

export const MyAuthContext = createContext<AuthContext>(defaultContext);

export const MyAuthProvider: React.FC<React.PropsWithChildren<{ testing: boolean }>> = props => {
    const [authUser, setAuthUser] = useState<Me>(props.testing ? testAuth : defaultAuth);

    return (
        <MyAuthContext.Provider
            value={{
                authState: [authUser, setAuthUser],
                hasPermissions: hasPermissions(authUser.permissions)
            }}
            {...props}
        />
    );
};

export function useAuth() {
    return useContext(MyAuthContext);
}
