import { LatLng } from 'src/framework/api/models/gmaps-models';

export const validateGetPlaceDetails = (response: any): LatLng => {
    if (response.status === 'OK') {
        return {
            lat: response.result.geometry.location.lat,
            lng: response.result.geometry.location.lng
        };
    }

    throw new Error('structure place details incorrect');
};
