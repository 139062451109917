import { RouteN } from '../../routes-path';

export const goToDevicesList = () => {
    return RouteN.devices.route;
};

export const goToDevicesCreate = () => {
    return RouteN.devices.route + '/' + RouteN.devices.children.create;
};

export const goToDeviceDetail = (id: string, extra?: string) => {
    return RouteN.devices.route + '/' + RouteN.devices.children.detail.replace(':id', id) + (extra ?? '');
};

export const goToEditScript = (id: string, scriptId: string) => {
    return (
        RouteN.devices.route +
        '/' +
        RouteN.devices.children.editScript.replace(':id', id).replace(':scriptId', scriptId)
    );
};

export const goToCreateScript = (deviceId: string) => {
    return (
        RouteN.devices.route + '/' + RouteN.devices.children.createScript.replace(':id', deviceId)
    );
};
