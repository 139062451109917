import { getAuth } from './endpoints/auth';
import { getDevice } from './endpoints/device';
import { getScripts } from './endpoints/scripts';
import { getTags } from './endpoints/tags';
import { getUser } from './endpoints/user';
import { HTTPError } from 'framework/utils';
import { getGMaps } from './endpoints/gmaps';
import { getTokenLocalStorage, setTokenLocalStorage } from 'framework/utils/login';
import { getAlert } from 'framework/api/endpoints/alerts';

const API_URL = process.env.REACT_APP_API_URL || '';

export type API_METHODS = 'GET' | 'PUT' | 'POST' | 'DELETE' | 'PATCH';

export type MyApiType = ReturnType<typeof buildMyApi>;

export type MyApiRequest = (
    url: string,
    method: API_METHODS,
    body?: Object,
    signal?: AbortSignal
) => Promise<Response>;

export function buildMyApi(closeSession: () => void) {
    let myApiHeaders = {
        'Content-Type': 'application/json',
        'Accept-Language': 'es-ES',
        Authorization: `Bearer ${getTokenLocalStorage()}`
    };

    function addAuthToHeader(token: string) {
        setTokenLocalStorage(token);
        myApiHeaders['Authorization'] = `Bearer ${token}`;
    }

    async function request(url: string, method: API_METHODS, body?: Object, signal?: AbortSignal) {
        const response = await fetch(API_URL + url, {
            method: method,
            headers: myApiHeaders,
            body: JSON.stringify(body),
            signal
        });

        if (response.status === 401) {
            closeSession();
            throw new HTTPError(response.status, response.statusText);
        }

        if (!response.ok) {
            throw new HTTPError(response.status, response.statusText);
        }

        return response;
    }

    function auth() {
        return getAuth(request);
    }

    function user() {
        return getUser(request);
    }

    function device() {
        return getDevice(request);
    }

    function tag() {
        return getTags(request);
    }

    function script() {
        return getScripts(request);
    }

    function gmaps() {
        return getGMaps(request);
    }

    function alert() {
        return getAlert(request);
    }

    return { auth, user, device, tag, script, gmaps, addAuthToHeader, alert };
}
