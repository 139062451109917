import { Components, Theme } from '@mui/material';
import { theme } from './theme';

export const appComponents: Components<Omit<Theme, 'components'>> = {
    MuiCssBaseline: {
        styleOverrides: {
            body: {
                '&::-webkit-scrollbar, *::-webkit-scrollbar': {
                    width: 18,
                    height: 16,
                    backgroundColor: theme.palette.common.white
                },

                '&::-webkit-scrollbar-thumb, *::-webkit-scrollbar-thumb': {
                    borderRadius: 8,
                    backgroundColor: theme.palette.common.grayBorder,
                    border: '5px solid transparent',
                    backgroundClip: 'padding-box'
                },
                '&::-webkit-scrollbar-thumb:focus, *::-webkit-scrollbar-thumb:focus': {
                    backgroundColor: theme.palette.common.gray
                },
                '&::-webkit-scrollbar-thumb:active, *::-webkit-scrollbar-thumb:active': {
                    backgroundColor: theme.palette.common.gray
                },
                '&::-webkit-scrollbar-thumb:hover, *::-webkit-scrollbar-thumb:hover': {
                    backgroundColor: theme.palette.common.gray
                }
            }
        }
    },
    MuiButton: {
        defaultProps: {
            size: 'large'
        },
        styleOverrides: {
            root: {
                padding: '12px 14px 12px 14px !important',
                textTransform: 'none',
                fontWeight: 400,
                height: 48,
                '&.Mui-disabled': {
                    color: theme.palette.common.white
                }
            },
            sizeSmall: {
                fontSize: 14
            },
            sizeMedium: {
                fontSize: 16
            },
            sizeLarge: {
                fontSize: 18
            }
        }
    },
    MuiAlert: {
        styleOverrides: {
            standardInfo: {
                backgroundColor: theme.palette.common.blue0C + '14',
                border: `1px solid ${theme.palette.common.blue0C}`,
                color: theme.palette.primary.main
            }
        }
    },
    MuiInputBase: {
        styleOverrides: {
            root: {
                paddingRight: '16px !important',
                '&.MuiAutocomplete-inputRoot': {
                    paddingRight: '39px !important'
                },
                '& fieldset': {
                    borderColor: theme.palette.common.grayAD
                }
            },
            input: {
                '::placeholder': {
                    color: theme.palette.common.gray86
                },
                '&:-webkit-autofill': {
                    transitionDelay: '9999s',
                    transitionProperty: 'background-color, color'
                }
            }
        }
    },
    MuiSelect: {
        styleOverrides: {
            icon: {
                '&.Mui-disabled': {
                    display: 'none'
                },
                color: theme.palette.common.grayAD,
                marginRight: 6
            }
        }
    },
    MuiInputLabel: {
        styleOverrides: {
            root: {
                zIndex: 1
            }
        }
    },
    MuiAutocomplete: {
        styleOverrides: {
            root: {
                '& label, & label.Mui-focused': {
                    color: theme.palette.common.black
                }
            },
            input: {
                paddingRight: '20px !important'
            },
            clearIndicator: {
                color: theme.palette.common.grayAD
            },
            noOptions: {
                color: theme.palette.common.black
            }
        }
    },
    MuiFilledInput: {
        styleOverrides: {
            input: {
                '&:-webkit-autofill': {
                    WebkitBoxShadow: 'inherit',
                    WebkitTextFillColor: 'inherit',
                    caretColor: 'inherit'
                }
            }
        }
    },
    MuiOutlinedInput: {
        styleOverrides: {
            input: {
                '&:-webkit-autofill': {
                    WebkitBoxShadow: 'inherit',
                    WebkitTextFillColor: 'inherit',
                    caretColor: 'inherit'
                }
            }
        }
    },
    MuiCard: {
        defaultProps: {
            elevation: 0
        },
        styleOverrides: {
            root: {
                border: `1px solid ${theme.palette.common.grayBorder}`,
                background: theme.palette.common.white
            }
        }
    },
    MuiCardContent: {
        styleOverrides: {
            root: {
                padding: '0px !important'
            }
        }
    },
    MuiTableRow: {
        styleOverrides: {
            root: {
                height: 64,
                borderBottom: '1px solid ' + theme.palette.common.grayLight
            }
        }
    },
    MuiTableCell: {
        styleOverrides: {
            root: {
                borderBottom: 0,
                '&:last-child': {
                    paddingRight: 24
                },
                '&:first-of-type': {
                    paddingLeft: 24
                }
            }
        }
    },
    MuiTableSortLabel: {
        styleOverrides: {
            icon: {
                color: theme.palette.common.black + ' !important'
            },
            root: {
                color: theme.palette.common.black + ' !important'
            }
        }
    },
    MuiLink: {
        defaultProps: {
            color: theme.palette.common.black,
            underline: 'none'
        }
    },
    MuiSkeleton: {
        styleOverrides: {
            root: {
                backgroundColor: theme.palette.common.grayF1
            }
        }
    }
};
