import { User } from 'framework/api/models/user-models';

export const validateGetUserDetail = (userResponseDTO: any): User | undefined => {
    try {
        return validateUser(userResponseDTO);
    } catch {}
};

export const validateUser = (userDTO: any): User => {
    if ('id' in userDTO && 'name' in userDTO && 'email' in userDTO && 'role' in userDTO) {
        return {
            id: userDTO.id,
            name: userDTO.name,
            email: userDTO.email,
            role: userDTO.role
        };
    }

    throw new Error('structure user incorrect');
};
