import { QueryClient, useQueryClient } from '@tanstack/react-query';
import { cleanState } from 'framework/utils/url';
import { useAuth } from 'features/auth/framework/provider/auth-provider';
import { ROLES } from 'framework/utils/permissions';

const KEY_TOKEN = 'tokenJwt';

export const setTokenLocalStorage = (token: string) => {
    return localStorage.setItem(KEY_TOKEN, token);
};

export const getTokenLocalStorage = () => {
    return localStorage.getItem(KEY_TOKEN) || '';
};

export const clearSession = (queryClient: QueryClient) => {
    localStorage.clear();
    cleanState();
    queryClient.removeQueries();
};

export const useCloseSession = () => {
    const {
        authState: [, setAuth]
    } = useAuth();
    const queryClient = useQueryClient();

    const closeSession = () => {
        setAuth({
            id: '',
            email: '',
            name: '',
            role: 0 as ROLES,
            permissions: []
        });
        clearSession(queryClient);
        window.history.replaceState({ logout: true }, '');
    };

    return {
        closeSession
    };
};
