import { RoutesType } from './index';
import { RouteN } from 'routing/routes-path';
import { lazy } from 'react';
import { PERMISSIONS } from 'framework/utils';
import { Outlet } from 'react-router-dom';

const AlertList = lazy(() => import('src/features/alerts/framework/list/alert-list'));
const AlertDetail = lazy(() => import('src/features/alerts/framework/detail/alert-detail'));

export const AlertRoutes: RoutesType[] = [
    {
        path: RouteN.alerts.route,
        suspense: false,
        id: 'alerts',
        element: <Outlet />,
        permission: PERMISSIONS.ALERT_MANAGEMENT_VIEW,
        children: [
            {
                path: '',
                suspense: true,
                id: 'alerts-list',
                element: <AlertList />,
                permission: PERMISSIONS.ALERT_MANAGEMENT_VIEW
            },
            {
                path: RouteN.alerts.children.detail,
                suspense: true,
                id: 'alerts-detail',
                element: <AlertDetail />,
                permission: PERMISSIONS.ALERT_MANAGEMENT_VIEW
            }
        ]
    }
];
