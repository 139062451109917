import { createTheme } from '@mui/material/styles';
import { typographys } from './typography';

export const theme = createTheme({
    palette: {
        primary: {
            main: '#0D163E'
        },
        secondary: {
            main: '#0CAEFF'
        },
        text: {
            primary: '#000000',
            secondary: '#FFFFFF',
            disabled: '#7D7D7D'
        },
        background: {
            paper: '#F8F9FA',
            default: '#F8F9FA'
        },
        error: {
            main: '#FF5A45'
        },
        common: {
            black: '#000',
            black21: '#212529',
            blue0C: '#0CAEFF',
            blue4A: '#4A90E2',
            grayC6: '#C6C6C6',
            grayAD: '#ADB5BD',
            grayDE: '#DEE2E6',
            grayDA: '#DAE1E7',
            gray86: '#868E96',
            gray38: '#383C40',
            gray51: '#515C65',
            grayF2: '#F2F2F2',
            grayF1: '#F1F3F5',
            grayF8: '#F8F9FA',
            gray73: '#737B82',
            gray49: '#495057',
            grayLight: '#D7D7D7',
            grayAdornment: '#B5B5B5',
            grayBorder: '#E9ECEF',
            white: '#FFF',
            gray: '#7D7D7D',
            green: '#009B57',
            success: '#27C24C',
            red: '#FF5A45',
            orange: '#BA650A',
            transparent: 'transparent'
        },
        divider: '#DEE2E6'
    },
    breakpoints: {
        keys: ['xs', 'sm', 'md', 'lg', 'xl'],
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536
        }
    },
    zIndex: {
        appBottomNavigation: 13
    },
    typography: typographys,
    spacing: 1
});
