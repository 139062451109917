import { MyApiRequest } from 'framework/api/my-api';
import { CreateTagBody, EditTagBody, Tag } from 'framework/api/models/tag-models';
import { ItemList } from 'framework/api/models';
import { validateGetTagDetail, validateGetTags } from 'framework/api/validations/tags';

const TAGS_INDEX = 'tags';

export function getTags(request: MyApiRequest) {
    async function getTags(paramsURL: string): Promise<ItemList<Tag>> {
        const response = await request(TAGS_INDEX + paramsURL, 'GET');

        const data = await response.json();

        return validateGetTags(data);
    }

    async function getTag(id: string): Promise<Tag | undefined> {
        const response = await request(TAGS_INDEX + '/' + id, 'GET');

        const data = await response.json();

        return validateGetTagDetail(data);
    }

    async function postTag(requestBody: CreateTagBody): Promise<boolean> {
        const response = await request(TAGS_INDEX, 'POST', requestBody);

        return response.ok;
    }

    async function putTag(requestBody: EditTagBody): Promise<boolean> {
        const response = await request(TAGS_INDEX, 'PUT', requestBody);

        return response.ok;
    }

    async function deleteTag(idTag: string): Promise<boolean> {
        const response = await request(TAGS_INDEX + '/' + idTag, 'DELETE');

        return response.ok;
    }

    return {
        getTags,
        getTag,
        postTag,
        putTag,
        deleteTag
    };
}
