import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material';

export const useStyles = makeStyles()((theme: Theme) => ({
    drawer: {
        width: 222,
        backgroundColor: 'red',
        flexShrink: 0,
        '& .MuiDrawer-paper': {
            backgroundColor: theme.palette.common.white,
            border: 0,
            width: 222,
            boxSizing: 'border-box'
        }
    },
    paddingsBox: {
        paddingTop: 28,
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    boxBottom: {
        paddingBottom: '32px !important',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        padding: '10px 10px 0 10px'
    },
    listMargin: {
        paddingTop: 27
    },
    buttonStyles: {
        '&.MuiButton-outlined': {
            color: theme.palette.common.gray38,
            border: '1px solid ' + theme.palette.common.gray86 + '26'
        }
    },
    buttonStylesActive: {
        '&.MuiButton-outlined': {
            color: theme.palette.common.gray38,
            border: '1px solid ' + theme.palette.secondary.main,
            backgroundColor: theme.palette.secondary.main + '05'
        }
    }
}));
