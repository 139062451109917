type RelativeTimeFormatUnit =
    | 'year'
    | 'years'
    | 'quarter'
    | 'quarters'
    | 'month'
    | 'months'
    | 'week'
    | 'weeks'
    | 'day'
    | 'days'
    | 'hour'
    | 'hours'
    | 'minute'
    | 'minutes'
    | 'second'
    | 'seconds';

export const formatDate = (date: Date): string => {
    return new Intl.DateTimeFormat('es-ES', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
    }).format(date);
};

export const formatDateTime = (date: Date): string => {
    return new Intl.DateTimeFormat('es-ES', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
    })
        .format(date)
        .replace(',', '');
};

export const formatDateTimeWithoutYear = (date: Date): string => {
    try {
        const dateWithYear = new Intl.DateTimeFormat('es-ES', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit'
        }).format(date);

        return dateWithYear.replace(`/${date.getFullYear()},`, '');
    } catch {
        return '';
    }
};

export const formatDateTimeMonthShort = (date: Date, withHour = true): string => {
    return new Intl.DateTimeFormat('es-ES', {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
        hour: withHour ? '2-digit' : undefined,
        minute: withHour ? '2-digit' : undefined
    })
        .format(date)
        .replace(',', '');
};

export const relativeTimeFormat = (date: Date) => {
    const currentDate = new Date().getTime();
    const compareDate = date.getTime();

    const diff = compareDate - currentDate;

    const secs = Math.floor(Math.abs(diff) / 1000);
    const min = Math.floor(secs / 60);
    const hours = Math.floor(min / 60);
    const days = Math.floor(hours / 24);
    const sem = Math.floor(days / 7);
    const month = new Date(currentDate).getMonth() - new Date(compareDate).getMonth();

    let unit: RelativeTimeFormatUnit = 'seconds';
    let value = secs;

    if (!!month) {
        unit = 'months';
        value = month;
    } else if (!!sem) {
        unit = 'weeks';
        value = sem;
    } else if (!!days) {
        unit = 'days';
        value = days;
    } else if (!!hours) {
        unit = 'hours';
        value = hours;
    } else if (!!min) {
        unit = 'minutes';
        value = min;
    }

    const rtf = new Intl.RelativeTimeFormat('es', {
        numeric: 'always',
        style: 'long'
    });

    return rtf.format(Math.sign(diff) * value, unit);
};

export const daysBetween = (date1: Date, date2: Date): number => {
    let diff = Math.abs(date1.getTime() - date2.getTime());
    return diff / (1000 * 60 * 60 * 24);
};

export const setToStartOfTheDay = (startDate: Date): string => {
    return new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), 0, 0, 0)
        .getTime()
        .toString();
};

export const setToEndOfTheDay = (endDate: Date): string => {
    return new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), 23, 59, 59)
        .getTime()
        .toString();
};
