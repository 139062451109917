export enum AlertType {
    LIGHT = 1,
    MODERATE,
    SEVERE
}

export enum AlertStatus {
    UNREAD = 1,
    READ
}

export type Alert = {
    id: string;
    status: AlertStatus;
    alertType: AlertType;
    message: string;
    createdAt: Date;
    updatedAt: Date;
};

export type AlertWithDevice = Alert & {
    device: {
        id: string;
        name: string;
    };
    assetId?: string;
};

export type MarkReadAlertParams = {
    idToRead?: string;
};
