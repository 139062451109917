import { MyApiRequest } from 'framework/api/my-api';
import { ItemList } from 'framework/api/models';
import { validateGetAlertDetail } from 'framework/api/validations/alert/get-alert';
import { validateGetAlerts } from 'framework/api/validations/alert/get-alerts';
import { AlertWithDevice, MarkReadAlertParams } from 'framework/api/models/alert-models/alert';

const ALERT_INDEX = 'alerts';

export function getAlert(request: MyApiRequest) {
    async function getAlerts(paramsURL: string): Promise<ItemList<AlertWithDevice>> {
        const response = await request(ALERT_INDEX + paramsURL, 'GET');
        const data = await response.json();

        return validateGetAlerts(data);
    }

    async function getAlert(alertId: string): Promise<AlertWithDevice | undefined> {
        const response = await request(ALERT_INDEX + '/' + alertId, 'GET');
        const data = await response.json();

        return validateGetAlertDetail(data);
    }

    async function getAlertsUnread(): Promise<number> {
        const response = await request(ALERT_INDEX + '/unreadCount', 'GET');
        return await response.json();
    }

    async function readAlerts(params: MarkReadAlertParams): Promise<boolean> {
        const response = await request(
            ALERT_INDEX + '/markAsRead' + (!!params.idToRead ? '?id=' + params.idToRead : ''),
            'PATCH',
            {}
        );

        return response.ok;
    }

    return {
        getAlerts,
        getAlert,
        getAlertsUnread,
        readAlerts
    };
}
