export class HTTPError extends Error {
    statusCode: number;

    constructor(code: number, message: string) {
        super(message);
        this.name = 'HTTPError';
        this.statusCode = code;
    }
}

export default HTTPError;
