export const authKeys = {
    index: 'auth',
    login: 'login',
    resetPassword: 'reset-password',
    resetPasswordError: 'reset-password-error',
    newPassword: 'new-password'
};

export const userKeys = {
    index: 'user',
    users: 'users-list',
    userDetail: 'user-detail',
    edit: 'edit-user',
    editError: 'edit-user-error',
    create: 'create-user',
    createError: 'create-user-error',
    delete: 'delete-user',
    deleteError: 'delete-user-error',
    me: 'get-me'
};

export const deviceKeys = {
    index: 'device',
    devices: 'devices-list',
    create: 'create-device',
    edit: 'edit-device',
    editError: 'edit-device-error',
    createError: 'create-device-error',
    deviceDetail: 'device-detail',
    delete: 'delete-device',
    deleteError: 'delete-device-error',
    units: 'devices-units',
    unitsError: 'devices-units-error',
    values: 'devices-values',
    valuesError: 'devices-values-error'
};

export const tagKeys = {
    index: 'tag',
    tags: 'tags-list',
    tagDetail: 'tag-detail',
    edit: 'edit-tag',
    editError: 'edit-tag-error',
    create: 'create-tag',
    createError: 'create-tag-error',
    delete: 'delete-tag',
    deleteError: 'delete-tag-error'
};

export const scriptKeys = {
    index: 'script',
    scripts: 'scripts-list',
    scriptDetail: 'script-detail',
    edit: 'edit-script',
    editError: 'edit-script-error',
    create: 'create-script',
    createError: 'create-script-error',
    delete: 'delete-script',
    deleteError: 'delete-script-error'
};

export const gMapsKeys = {
    autocomplete: 'autocomplete',
    placeDetails: 'placeDetails',
    geocode: 'geocode'
};

export const alertsKeys = {
    index: 'alert',
    alerts: 'alerts-list',
    alertDetail: 'alert-detail',
    alertAssetId: 'alert-asset-id',
    alertsUnread: 'alerts-unread'
};

export const authKey = () => {
    return [authKeys.index];
};

export const authLoginKey = () => {
    return [authKeys.index, authKeys.login];
};

export const authResetPasswordKey = () => {
    return [authKeys.index, authKeys.resetPassword];
};

export const authNewPasswordKey = () => {
    return [authKeys.index, authKeys.newPassword];
};

export const getMeKey = () => {
    return [userKeys.me];
};

export const usersKey = () => {
    return [userKeys.index, userKeys.users];
};

export const userByIdKey = (id: string) => {
    return [userKeys.index, userKeys.userDetail, id];
};

export const devicesKey = () => {
    return [deviceKeys.index, deviceKeys.devices];
};

export const tagsKey = () => {
    return [tagKeys.index, tagKeys.tags];
};

export const tagsByIdKey = (id: string) => {
    return [tagKeys.index, tagKeys.tagDetail, id];
};

export const deviceByIdKey = (id: string) => {
    return [deviceKeys.index, deviceKeys.deviceDetail, id];
};

export const deviceDataUnitByIdKey = (id: string) => {
    return [deviceKeys.index, deviceKeys.deviceDetail, id, deviceKeys.units];
};

export const deviceDataValueByIdKey = (id: string) => {
    return [deviceKeys.index, deviceKeys.deviceDetail, id, deviceKeys.values];
};

export const scriptsKey = () => {
    return [scriptKeys.index, scriptKeys.scripts];
};

export const scriptsByIdKey = (id: string) => {
    return [scriptKeys.index, scriptKeys.scriptDetail, id];
};

export const alertsKey = () => {
    return [alertsKeys.index, alertsKeys.alerts];
};

export const alertsByIdKey = (id: string) => {
    return [alertsKeys.index, alertsKeys.alertDetail, id];
};

export const alertsAssetId = (id: string) => {
    return [alertsKeys.index, alertsKeys.alertAssetId, id];
};

export const alertsUnreadKey = () => {
    return [alertsKeys.index, alertsKeys.alerts, alertsKeys.alertsUnread];
};
