import { MyApiRequest } from 'framework/api/my-api';
import {
    LoginBody,
    LoginResponse,
    ResetPasswordBody,
    NewPasswordBody
} from 'framework/api/models/auth-models';
import { validatePostLogin } from 'framework/api/validations/auth';

export function getAuth(request: MyApiRequest) {
    async function postResetPassword(requestBody: ResetPasswordBody): Promise<boolean> {
        const response = await request('auth/recover-password', 'POST', requestBody);

        return response.ok;
    }

    async function postLogin(requestBody: LoginBody): Promise<LoginResponse> {
        const response = await request('auth/login', 'POST', requestBody);

        const data = await response.json();

        return validatePostLogin(data);
    }

    async function postNewPassword(requestBody: NewPasswordBody): Promise<boolean> {
        const response = await request('auth/reset-password', 'POST', requestBody);

        return response.ok;
    }

    return {
        postResetPassword,
        postLogin,
        postNewPassword
    };
}
