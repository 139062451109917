import React, { ReactElement } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useQueryGetMe } from 'framework/api/hooks/user-hooks/get-me';
import { LinearProgress } from '@mui/material';
import { RouteN } from 'routing/routes-path';
import { getTokenLocalStorage } from 'framework/utils/login';

const AuthenticationGuard = (props: { children: ReactElement }) => {
    const token = getTokenLocalStorage();
    const { user, isLoading } = useQueryGetMe(!!token);
    const location = useLocation();

    const comesFromLogout = window.history.state ? window.history.state.logout || false : false;

    return !token ? (
        <Navigate
            to={RouteN.auth.route + '/' + RouteN.auth.children.login}
            state={{ from: comesFromLogout ? undefined : location }}
            replace
        />
    ) : isLoading ? (
        <LinearProgress
            data-testid={'loading-get-me'}
            sx={{ position: 'absolute', top: 0, right: 0, left: 0 }}
        />
    ) : !user.id ? (
        <Navigate
            to={RouteN.auth.route + '/' + RouteN.auth.children.login}
            state={{ from: comesFromLogout ? undefined : location }}
            replace
        />
    ) : (
        props.children
    );
};

export default AuthenticationGuard;
