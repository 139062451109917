import { RouteN } from 'routing/routes-path';

export const goToConfig = () => {
    return RouteN.config.route;
};

export const goToTagManagementCreate = () => {
    return (
        RouteN.config.route +
        '/' +
        RouteN.config.children.tagManagement.route +
        '/' +
        RouteN.config.children.tagManagement.children.create
    );
};

export const goToTagManagementEdit = (id: string) => {
    return (
        RouteN.config.route +
        '/' +
        RouteN.config.children.tagManagement.route +
        '/' +
        RouteN.config.children.tagManagement.children.edit.replace(':id', id)
    );
};
