import { PredictionGMaps } from 'src/framework/api/models/gmaps-models';

export const validateGetAutocomplete = (response: any): PredictionGMaps[] => {
    const predictionsTmp: PredictionGMaps[] = [];

    if (response.status !== 'OK') return predictionsTmp;
    if ('predictions' in response) {
        response.predictions.forEach((p: any) => {
            if ('place_id' in p && 'description' in p) {
                predictionsTmp.push({ name: p.description, placeId: p.place_id });
            }
        });
    }
    if ('results' in response) {
        response.results.forEach((r: any) => {
            if ('formatted_address' in r && 'place_id' in r) {
                predictionsTmp.push({ name: r.formatted_address, placeId: r.place_id });
            }
        });
    }

    return predictionsTmp;
};
