import { Script } from 'framework/api/models/script-models';
import { ItemList } from 'framework/api/models';
import { validateScript } from './get-script';

export const validateGetScripts = (scriptsResponseDTO: any): ItemList<Script> => {
    return {
        items: 'items' in scriptsResponseDTO ? validateScripts(scriptsResponseDTO.items) : [],
        count: 'count' in scriptsResponseDTO ? scriptsResponseDTO.count : 0
    };
};

export const validateScripts = (scriptsDTO: any[]): Script[] => {
    if (!scriptsDTO || !scriptsDTO.length) return [];

    const scripts: Script[] = [];

    scriptsDTO.forEach((sc: any) => {
        const script = validateScript(sc);
        script && scripts.push(script);
    });

    return scripts;
};
