import { useI18nService } from 'framework/i18n/i18n-service';
import { useMyApi } from 'framework/api/api-provider';
import { useNotificationService } from 'framework/notifications/notifications-service';
import { useQuery } from '@tanstack/react-query';
import { alertsKeys, alertsUnreadKey } from 'framework/api/hooks';

export function useQueryGetUnreadAlerts(havePermission: boolean) {
    const { t } = useI18nService();
    const api = useMyApi();
    const notification = useNotificationService();

    const { isLoading, data, isError } = useQuery(
        alertsUnreadKey(),
        () => api.alert().getAlertsUnread(),
        {
            enabled: havePermission,
            onError: () => {
                notification(t('internalError'), {
                    variant: 'error',
                    preventDuplicate: true,
                    key: alertsKeys.index
                });
            }
        }
    );

    return {
        isLoading,
        unread: data ?? 0,
        isError
    };
}
