import { menuItems, menuItemConfig } from './menu-items';

export const changeItemActive = (pathname: string) => {
    if (pathname) {
        const pathSplit = pathname.split('/');

        const itemSeparate = menuItemConfig.key === pathSplit[1];

        if (itemSeparate) {
            return menuItemConfig.key;
        }

        if (pathSplit.length > 1) {
            const item = menuItems.find(el => el.path.includes(pathSplit[1]));
            return item && item.key ? item.key : '';
        }
    }
    return '';
};
