import { createTheme } from '@mui/material/styles';
import { theme } from './theme';
import { appTypographys } from './app-typography';
import { appComponents } from './app-components';

const appTheme = createTheme({
    palette: {
        primary: {
            main: theme.palette.primary.main
        },
        secondary: {
            main: theme.palette.secondary.main
        },
        text: {
            primary: theme.palette.text.primary,
            secondary: theme.palette.text.secondary,
            disabled: theme.palette.text.disabled
        },
        background: {
            paper: theme.palette.background.paper,
            default: theme.palette.background.default
        },
        error: {
            main: theme.palette.error.main
        },
        common: {
            black: theme.palette.common.black,
            white: theme.palette.common.white,
            blue0C: theme.palette.common.blue0C,
            blue4A: theme.palette.common.blue4A,
            black21: theme.palette.common.black21,
            gray: theme.palette.common.gray,
            grayC6: theme.palette.common.grayC6,
            grayAD: theme.palette.common.grayAD,
            grayDE: theme.palette.common.grayDE,
            grayDA: theme.palette.common.grayDA,
            gray86: theme.palette.common.gray86,
            gray38: theme.palette.common.gray38,
            gray51: theme.palette.common.gray51,
            grayF2: theme.palette.common.grayF2,
            grayF1: theme.palette.common.grayF1,
            grayF8: theme.palette.common.grayF8,
            gray73: theme.palette.common.gray73,
            gray49: theme.palette.common.gray49,
            grayAdornment: theme.palette.common.grayAdornment,
            grayBorder: theme.palette.common.grayBorder,
            success: theme.palette.common.success,
            grayLight: theme.palette.common.grayLight,
            green: theme.palette.common.green,
            red: theme.palette.common.red,
            orange: theme.palette.common.orange,
            transparent: theme.palette.common.transparent
        },
        divider: theme.palette.divider
    },
    breakpoints: {
        keys: theme.breakpoints.keys,
        values: {
            xs: theme.breakpoints.values.xs,
            sm: theme.breakpoints.values.sm,
            md: theme.breakpoints.values.md,
            lg: theme.breakpoints.values.lg,
            xl: theme.breakpoints.values.xl
        }
    },
    zIndex: {
        appBottomNavigation: theme.zIndex.appBottomNavigation
    },
    typography: appTypographys,
    spacing: theme.spacing,
    components: appComponents
});

export default appTheme;
