import { ItemList } from 'framework/api/models';
import { validateAlert } from 'framework/api/validations/alert/get-alert';
import { AlertWithDevice } from 'framework/api/models/alert-models/alert';

export const validateGetAlerts = (alertsResponseDTO: any): ItemList<AlertWithDevice> => {
    return {
        items: 'items' in alertsResponseDTO ? validateAlerts(alertsResponseDTO.items) : [],
        count: 'count' in alertsResponseDTO ? alertsResponseDTO.count || 0 : 0
    };
};

const validateAlerts = (alertsDTO: any[]): AlertWithDevice[] => {
    const alerts: AlertWithDevice[] = [];

    alertsDTO.forEach((alertDTO: any) => {
        const alert = validateAlert(alertDTO);
        if (alert) {
            alerts.push(alert);
        }
    });

    return alerts;
};
