import * as React from 'react';
import { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

export type SupportedLanguages = 'es';
export type I18nService = {
    t: (key: string, options?: unknown) => string;
    Trans: (...args: any[]) => React.ReactElement;
    changeLanguage: (language: SupportedLanguages) => void;
    currentLanguage: SupportedLanguages;
};

export const defaultI18nProvider: I18nService = {
    t: (key: string) => key,
    Trans: props => <div {...props} />,
    changeLanguage: () => undefined,
    currentLanguage: 'es'
};
const I18nContext = React.createContext<I18nService>(defaultI18nProvider);

export const MyI18nProvider: React.FC<React.PropsWithChildren<{}>> = props => {
    const { t, i18n } = useTranslation();
    const tI18n = React.useRef(t);
    const [language, setLanguage] = React.useState<SupportedLanguages>(
        i18n.language as SupportedLanguages
    );
    const changeLanguage = React.useCallback(
        (language: SupportedLanguages) => {
            i18n.changeLanguage(language)
                .then(t => {
                    tI18n.current = t;
                    setLanguage(i18n.language as SupportedLanguages);
                })
                .catch(e => {
                    console.log('Error changing language', e);
                });
        },
        [i18n]
    );
    const translationService = useMemo(
        () => ({
            t: tI18n.current,
            Trans,
            changeLanguage,
            currentLanguage: language
        }),
        [changeLanguage, language]
    );

    return <I18nContext.Provider value={translationService} {...props} />;
};

export const useI18nService = () => {
    return React.useContext(I18nContext);
};
