import React, { memo, ReactElement } from 'react';
import { Navigate } from 'react-router-dom';
import { RouteN } from 'routing/routes-path';
import { getTokenLocalStorage } from 'framework/utils/login';

const ProtectedAuthRoutes = (props: { children: ReactElement }) => {
    const haveToken = !!getTokenLocalStorage();

    return haveToken ? <Navigate to={RouteN.users.route} replace /> : props.children;
};

export default memo(ProtectedAuthRoutes);
