import { RouteN } from '../../routes-path';

export const goToUsersList = () => {
    return RouteN.users.route;
};

export const goToCreateUser = () => {
    return RouteN.users.route + '/' + RouteN.users.children.create;
};

export const goToEditUser = (id: string) => {
    return RouteN.users.route + '/' + RouteN.users.children.edit.replace(':id', id);
};
