import { Box, Link, Typography } from '@mui/material';
import { useI18nService } from 'framework/i18n/i18n-service';
import { useStyles } from './footer.style';
import { goToPrivacy } from 'routing/framework/navigates';
import { goToTerms } from 'routing/framework/navigates';
import { useNavigate } from 'react-router-dom';

export const Footer = () => {
    const { t } = useI18nService();
    const { classes: styles } = useStyles();
    const navigate = useNavigate();
    return (
        <Box data-testid={'footer'} className={styles.footerContainer}>
            <Box className={styles.footerItems}>
                <Typography variant={'body'}>
                    {t('copyright', { year: new Date().getFullYear() })}
                </Typography>
                <Link
                    href={goToPrivacy()}
                    onClick={e => {
                        e.preventDefault();
                        navigate(goToPrivacy());
                    }}
                    underline={'always'}
                >
                    <Typography variant={'body'}>{t('privacy')}</Typography>
                </Link>
                <Link
                    href={goToTerms()}
                    onClick={e => {
                        e.preventDefault();
                        navigate(goToTerms());
                    }}
                    underline={'always'}
                >
                    <Typography variant={'body'}>{t('terms')}</Typography>
                </Link>
            </Box>
        </Box>
    );
};

export default Footer;
