export const enum PERMISSIONS {
    USER_MANAGEMENT_VIEW = 1,
    USER_MANAGEMENT_CREATE,
    USER_MANAGEMENT_EDIT,
    USER_MANAGEMENT_DELETE,
    DEVICE_MANAGEMENT_VIEW = 11,
    DEVICE_MANAGEMENT_CREATE,
    DEVICE_MANAGEMENT_EDIT,
    DEVICE_MANAGEMENT_DELETE,
    ALERT_MANAGEMENT_VIEW = 21,
    ALERT_MANAGEMENT_EDIT
}

export enum ROLES {
    Admin = 1,
    DeviceViewer,
    Viewer,
    Editor,
    DeviceAdmin
}

export const roles = (): Record<ROLES, string> => ({
    [ROLES.Admin]: 'roleAdmin',
    [ROLES.DeviceViewer]: 'roleDeviceViewer',
    [ROLES.Viewer]: 'roleViewer',
    [ROLES.Editor]: 'roleEditor',
    [ROLES.DeviceAdmin]: 'roleDeviceAdmin'
});
