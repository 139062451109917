export const RouteN = {
    auth: {
        route: '/auth',
        children: {
            login: 'signin',
            reset: 'reset',
            newPassword: 'new-password'
        }
    },
    users: {
        route: '/users',
        children: {
            create: 'create',
            edit: 'edit/:id'
        }
    },
    devices: {
        route: '/devices',
        children: {
            create: 'create',
            edit: 'edit/:id',
            detail: 'detail/:id',
            editScript: 'edit/:id/edit-script/:scriptId',
            createScript: 'create/:id'
        }
    },
    config: {
        route: '/config',
        children: {
            tagManagement: {
                route: 'tag-management',
                children: {
                    create: 'create',
                    edit: 'edit/:id'
                }
            }
        }
    },
    alerts: {
        route: '/alerts',
        children: {
            detail: ':id'
        }
    },
    terms: {
        route: '/terms'
    },
    privacy: {
        route: '/privacy'
    }
};
