import { DeviceDataValues, Device } from 'framework/api/models/device-models/device';

export const validateGetDeviceDetail = (deviceResponseDTO: any): Device | undefined => {
    try {
        return validateDevice(deviceResponseDTO);
    } catch {}
};

export const validateDevice = (device: any): Device => {
    if (
        'id' in device &&
        'token' in device &&
        'name' in device &&
        'status' in device &&
        'createdAt' in device &&
        'updatedAt' in device &&
        'privateId' in device &&
        'tags' in device
    ) {
        return {
            privateId: device.privateId,
            id: device.id,
            token: device.token,
            name: device.name,
            status: device.status,
            description: device.description || '',
            createdAt: device.createdAt,
            updatedAt: device.updatedAt,
            isIgnored: device.isIgnored || false,
            tags: device.tags,
            isOutOfSync: device.isOutOfSync || false,
            address: device.address || '',
            latLng: device.latLng || ''
        };
    }

    throw new Error('structure device incorrect');
};

export const validateDeviceDataUnits = (deviceTypeDTO: any): string[] => {
    const units: string[] = [];
    if (!deviceTypeDTO || !deviceTypeDTO.length) return units;

    deviceTypeDTO.forEach((device: any) => {
        if (typeof device === 'string') units.push(device);
    });

    return units;
};

export const validateDeviceDataValues = (deviceDataDTO: any): DeviceDataValues[] => {
    const values: DeviceDataValues[] = [];
    if (!deviceDataDTO || !deviceDataDTO.length) return values;

    deviceDataDTO.forEach((data: any) => {
        if ('createdAt' in data && 'value' in data) {
            values.push({ createdAt: data.createdAt, value: Number(data.value) });
        }
    });

    return values;
};
