import { Script } from 'framework/api/models/script-models';

export const validateGetScriptDetail = (scriptResponseDTO: any): Script | undefined => {
    return validateScript(scriptResponseDTO);
};

export const validateScript = (script: any): Script | undefined => {
    if ('name' in script && 'id' in script && 'script' in script) {
        return {
            id: script.id,
            name: script.name,
            deviceId: script.deviceId,
            script: script.script,
            cron: script.cron || '',
            updatedAt: script.updatedAt || ''
        };
    }
};
