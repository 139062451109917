import { useI18nService } from 'framework/i18n/i18n-service';
import { useMyApi } from 'framework/api/api-provider';
import { useNotificationService } from 'framework/notifications/notifications-service';
import { useQuery } from '@tanstack/react-query';
import { alertsByIdKey, alertsKeys } from 'framework/api/hooks';
import { useMutationMarkReadAlert } from 'framework/api/hooks/alert-hooks/mark-read-alert';
import { AlertStatus } from 'framework/api/models/alert-models';

export function useQueryGetAlert(id: string, permissionsToEdit: boolean) {
    const { t } = useI18nService();
    const api = useMyApi();
    const notification = useNotificationService();

    const { mutate } = useMutationMarkReadAlert();

    const { isLoading, data, isError } = useQuery(
        alertsByIdKey(id),
        () => api.alert().getAlert(id),
        {
            enabled: !!id,
            onSuccess: data => {
                if (!!data && data.status === AlertStatus.UNREAD && permissionsToEdit) {
                    mutate({ idToRead: data.id });
                }
            },
            onError: () => {
                notification(t('internalError'), {
                    variant: 'error',
                    preventDuplicate: true,
                    key: alertsKeys.index
                });
            }
        }
    );

    return {
        isLoading,
        alert: data,
        isError
    };
}
