import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material';

export const useStyles = makeStyles()((theme: Theme) => ({
    paperZIndex: {
        zIndex: theme.zIndex.appBottomNavigation
    },
    appBottomNavigation: {
        height: 80
    },
    label: {
        [theme.breakpoints.down('sm')]: {
            fontSize: 12
        }
    },
    appBottomNavigationAction: {
        paddingTop: 15,
        paddingBottom: 10,
        paddingLeft: 2,
        paddingRight: 2,
        justifyContent: 'space-between',
        maxWidth: '25%',
        color: 'red',
        '& .MuiBottomNavigationAction-label': {
            marginTop: 0,
            '& span': {
                color: theme.palette.common.gray
            }
        }
    },
    appBottomNavigationActionSelected: {
        '& .MuiBottomNavigationAction-label': {
            marginTop: 0,
            '& span': {
                color: theme.palette.secondary.main + ' !important'
            }
        }
    }
}));
