import { KeyValue } from 'framework/api/models';
import { ROLES, roles } from './permissions';

export const getRolesToKeyValue = (t: (key: string, options?: unknown) => string): KeyValue[] => {
    const rolesTmp = roles();
    return Object.keys(rolesTmp).map(rolKey => ({
        key: rolKey,
        value: t(rolesTmp[rolKey as unknown as ROLES])
    }));
};
