import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { clearSession } from 'framework/utils/login';
import { useMyApi } from 'framework/api/api-provider';
import { getMeKey } from 'framework/api/hooks/query-keys';
import { useAuth } from 'features/auth/framework/provider/auth-provider';
import { useQueryClient } from '@tanstack/react-query';

export function useQueryGetMe(enabled: boolean) {
    const api = useMyApi();
    const queryClient = useQueryClient();
    const {
        authState: [auth, setAuth]
    } = useAuth();

    const { isLoading: isLoadingGet } = useQuery(getMeKey(), api.user().getMe, {
        enabled,
        onSuccess: res => {
            setAuth(res);
            setIsLoading(false);
        },
        onError: () => {
            clearSession(queryClient);
            setIsLoading(false);
        }
    });

    const [isLoading, setIsLoading] = useState(isLoadingGet);

    return {
        user: auth,
        isLoading
    };
}
