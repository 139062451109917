import { Box, Drawer, Link, List } from '@mui/material';
import ItemNavigation from './item-navigation';
import { useI18nService } from 'framework/i18n/i18n-service';
import { useLocation, useNavigate } from 'react-router-dom';
import { menuItemConfig, menuItems } from './utils/menu-items';
import { changeItemActive } from './utils/utils';
import { useStyles } from './app-drawer.style';
import Logo from 'resources/logo';
import { goToPrincipal } from 'routing/framework/navigates';
import { SharedButton } from 'framework/web/components/shared-button';
import { Me } from 'framework/api/models/user-models';

type AppDrawerProps = {
    unreadAlerts: number;
    auth: Me;
};

const AppDrawer = (props: AppDrawerProps) => {
    const { t } = useI18nService();
    let navigate = useNavigate();
    let location = useLocation();
    const { classes: styles } = useStyles();

    const itemNavActive = changeItemActive(location.pathname);

    const onClickItemMenu = (path: string) => {
        navigate(path);
    };

    return (
        <Drawer
            data-testid={'app-drawer'}
            variant={'permanent'}
            anchor={'left'}
            className={styles.drawer}
        >
            <Box className={styles.paddingsBox}>
                <Box
                    onClick={() => navigate(goToPrincipal())}
                    style={{ alignSelf: 'center', cursor: 'pointer' }}
                >
                    <Logo maxWidth={160} />
                </Box>
                <List className={styles.listMargin}>
                    {menuItems
                        .filter(menu => props.auth.permissions.includes(menu.permission))
                        .map((item, index) => (
                            <Link
                                key={index}
                                href={item.path}
                                onClick={e => {
                                    e.preventDefault();
                                    onClickItemMenu(item.path);
                                }}
                                data-testid={`link-item-navigation-${item.key}`}
                            >
                                <ItemNavigation
                                    title={t(item.label)}
                                    icon={item.icon}
                                    path={item.path}
                                    isActive={item.key === itemNavActive}
                                    count={menuItems[2].key === item.key ? props.unreadAlerts : 0}
                                />
                            </Link>
                        ))}
                </List>
                <Box className={styles.boxBottom}>
                    <SharedButton
                        href={menuItemConfig.path}
                        fullWidth={true}
                        className={
                            itemNavActive === menuItemConfig.key
                                ? styles.buttonStylesActive
                                : styles.buttonStyles
                        }
                        size={'medium'}
                        variant={'outlined'}
                        onClick={() => onClickItemMenu(menuItemConfig.path)}
                        startIcon={menuItemConfig.icon}
                        title={t(menuItemConfig.label)}
                    />
                </Box>
            </Box>
        </Drawer>
    );
};

export default AppDrawer;
