const DevicesMenu = (props: { size?: number; color?: string }) => {
    return (
        <svg height={props.size || 24} viewBox='0 0 24 24' width={props.size || 24}>
            <g fill='none' fillRule='evenodd'>
                <path
                    d='m22 9v-2h-2v-2c0-1.1-.9-2-2-2h-14c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-2h2v-2h-2v-2h2v-2h-2v-2zm-4 10h-14v-14h14zm-12-6h5v4h-5zm6-6h4v3h-4zm-6 0h5v5h-5zm6 4h4v6h-4z'
                    fill={props.color || '#000'}
                    fillRule='nonzero'
                />
                <path d='m0 0h24v24h-24zm0 0h24v24h-24z' />
            </g>
        </svg>
    );
};

export default DevicesMenu;
