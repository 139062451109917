import { createSearchParams } from 'react-router-dom';
import { QueryParam, PaginatedProps } from 'framework/api/models';
import { QueryProps } from 'framework/api/models/common';

export const cleanState = () => {
    window.history.replaceState({}, '');
};

export const addOrRemoveParamsFromURLV2 = (
    keyValue: { key: string; values: string[] }[]
): { [key: string]: string[] } => {
    const currentParams = createSearchParams(window.location.search); //same as URLSearchParams
    let params: { [key: string]: string[] } = {};
    Array.from(currentParams.keys()).forEach(k => {
        params[k] = currentParams.getAll(k);
    });

    keyValue.forEach(({ key, values }) => {
        if (!!values.length) {
            params[key] = values;
        } else {
            delete params[key];
        }
    });

    return params;
};

export const buildPaginatedParams = <TQuery, TSort>(queryProps: PaginatedProps<TQuery, TSort>) => {
    const q = queryProps.query ? getURLSearchParams<TQuery>(queryProps.query) : '';
    const s = queryProps.sort
        ? `sortName=${String(queryProps.sort.field)}&sortDirection=${queryProps.sort.direction}&`
        : '';
    const p = `offset=${queryProps.pager.offset}&limit=${queryProps.pager.limit}`;
    return '?' + q + s + p;
};

export const buildQueryParams = <TQuery>(queryProps: QueryProps<TQuery>) => {
    const q = queryProps.query ? getURLSearchParams<TQuery>(queryProps.query) : '';
    return '?' + q;
};

const getURLSearchParams = <T>(query: QueryParam<T>[]): string => {
    let params = query
        ? Object.fromEntries(
              query.filter(qp => !!qp.value).map(qp => [[qp.name], qp.value.toString().trim()])
          )
        : '';
    if (!!Object.keys(params).length) {
        return new URLSearchParams(params).toString() + '&';
    }
    return '';
};

export const openInTab = (url: string) => {
    const newTab = window.open(url, '_blank', 'noopener,noreferrer');
    if (newTab) newTab.opener = null;
};
