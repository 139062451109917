import { ReactElement } from 'react';
import {
    goToDevicesList,
    goToConfig,
    goToUsersList,
    goToAlertList
} from 'routing/framework/navigates';
import { PERMISSIONS } from 'framework/utils';
import UsersMenu from 'resources/users-menu';
import DevicesMenu from 'resources/devices-menu';
import ConfigMenu from 'resources/config-menu';
import Alerts from 'resources/alerts';

export type MenuItemType = {
    label: string;
    icon: ReactElement;
    path: string;
    key: string;
    permission: number;
};

export enum MENU_ITEMS {
    USERS = 'item-nav-users',
    DEVICES = 'item-nav-devices',
    CONFIGURATION = 'item-nav-config',
    ALERTS = 'item-nav-alerts'
}

export const menuItems: MenuItemType[] = [
    {
        label: MENU_ITEMS.USERS,
        permission: PERMISSIONS.USER_MANAGEMENT_VIEW,
        icon: <UsersMenu />,
        path: goToUsersList(),
        key: 'users'
    },
    {
        label: MENU_ITEMS.DEVICES,
        permission: PERMISSIONS.DEVICE_MANAGEMENT_VIEW,
        icon: <DevicesMenu />,
        path: goToDevicesList(),
        key: 'devices'
    },
    {
        label: MENU_ITEMS.ALERTS,
        permission: PERMISSIONS.ALERT_MANAGEMENT_VIEW,
        icon: <Alerts />,
        path: goToAlertList(),
        key: 'alerts'
    }
];

export const KEY_CONFIG = 'config';

export const menuItemConfig: Omit<MenuItemType, 'permission'> = {
    label: MENU_ITEMS.CONFIGURATION,
    path: goToConfig(),
    key: KEY_CONFIG,
    icon: <ConfigMenu />
};
