export const findLowestValue = (array: number[]): number => {
    let lowestValue = array[0];
    array.forEach(item => {
        if (item < lowestValue) {
            lowestValue = item;
        }
    });
    return lowestValue;
};

export const findHighestValue = (array: number[]): number => {
    let highestValue = array[0];
    array.forEach(item => {
        if (item > highestValue) {
            highestValue = item;
        }
    });
    return highestValue;
};
