import { Me } from 'framework/api/models/user-models';

export const validateGetMe = (usersResponseDTO: any): Me => {
    if ('id' in usersResponseDTO && 'permissions' in usersResponseDTO) {
        return {
            id: usersResponseDTO.id,
            email: usersResponseDTO.email,
            name: usersResponseDTO.name,
            role: usersResponseDTO.role,
            permissions: usersResponseDTO.permissions
        };
    }

    throw new Error('structure user incorrect');
};
