import { Tag } from 'framework/api/models/tag-models';

export const validateGetTagDetail = (tagResponseDTO: any): Tag | undefined => {
    try {
        return validateTag(tagResponseDTO);
    } catch {}
};

export const validateTag = (tagDTO: any): Tag => {
    if ('name' in tagDTO && 'id' in tagDTO && 'createdAt' in tagDTO && 'updatedAt' in tagDTO) {
        return {
            id: tagDTO.id,
            name: tagDTO.name,
            createdAt: tagDTO.createdAt,
            updatedAt: tagDTO.updatedAt
        };
    }
    throw new Error('structure tag incorrect');
};
