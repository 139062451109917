import { AlertWithDevice } from 'framework/api/models/alert-models/alert';

export const validateGetAlertDetail = (alertResponseDTO: any): AlertWithDevice | undefined => {
    try {
        return validateAlert(alertResponseDTO);
    } catch {}
};

export const validateAlert = (alertTmp: any): AlertWithDevice => {
    if (
        'id' in alertTmp &&
        'status' in alertTmp &&
        'alertType' in alertTmp &&
        'message' in alertTmp &&
        'createdAt' in alertTmp &&
        'updatedAt' in alertTmp &&
        'device' in alertTmp &&
        'id' in alertTmp.device &&
        'name' in alertTmp.device
    ) {
        return {
            id: alertTmp.id,
            status: alertTmp.status,
            alertType: alertTmp.alertType,
            message: alertTmp.message,
            createdAt: alertTmp.createdAt,
            updatedAt: alertTmp.updatedAt,
            assetId: alertTmp.assetId,
            device: alertTmp.device
        };
    }

    throw new Error('structure alert incorrect');
};
