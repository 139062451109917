const ConfigMenu = () => {
    return (
        <svg height='24' viewBox='0 0 24 24' width='24'>
            <g fill='none' fillRule='evenodd'>
                <path d='m0 0h24v24h-24z' />
                <g fill='#000' fillRule='nonzero' transform='translate(2 4)'>
                    <circle cx='8' cy='4' r='4' />
                    <path d='m8.67 9.02c-.22-.01-.44-.02-.67-.02-2.42 0-4.68.67-6.61 1.82-.88.52-1.39 1.5-1.39 2.53v2.65h9.26c-.79-1.13-1.26-2.51-1.26-4 0-1.07.25-2.07.67-2.98z' />
                    <path d='m18.75 12c0-.22-.03-.42-.06-.63l1.14-1.01-1-1.73-1.45.49c-.32-.27-.68-.48-1.08-.63l-.3-1.49h-2l-.3 1.49c-.4.15-.76.36-1.08.63l-1.45-.49-1 1.73 1.14 1.01c-.03.21-.06.41-.06.63s.03.42.06.63l-1.14 1.01 1 1.73 1.45-.49c.32.27.68.48 1.08.63l.3 1.49h2l.3-1.49c.4-.15.76-.36 1.08-.63l1.45.49 1-1.73-1.14-1.01c.03-.21.06-.41.06-.63zm-3.75 2c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z' />
                </g>
            </g>
        </svg>
    );
};

export default ConfigMenu;
