import { CssBaseline } from '@mui/material';
import ThemeProvider from '@mui/system/ThemeProvider';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './styles.css';
import appTheme from './theme/app-theme';
import 'framework/i18n';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import routes from 'routing/framework/routes';
import { buildRoutes, filterRoutesWithPermissions } from 'routing/framework';
import { MySnackbarProvider } from 'framework/notifications/notifications-service';
import { MyI18nProvider } from 'framework/i18n/i18n-service';
import { MyApiProvider } from 'framework/api/api-provider';
import { MyAuthProvider, useAuth } from 'features/auth/framework/provider/auth-provider';
import { FC, PropsWithChildren } from 'react';

export const muiCache = createCache({
    key: 'mui',
    prepend: true
});

export const AppDependencies: FC<
    PropsWithChildren<{
        queryClient: QueryClient;
        testing: boolean;
    }>
> = ({ children, testing, queryClient }) => {
    return (
        <CacheProvider value={muiCache}>
            <ThemeProvider theme={appTheme}>
                <MySnackbarProvider>
                    <CssBaseline />
                    <MyI18nProvider>
                        <QueryClientProvider client={queryClient}>
                            <MyAuthProvider testing={testing}>
                                <MyApiProvider>
                                    {children}
                                    <ReactQueryDevtools initialIsOpen={false} />
                                </MyApiProvider>
                            </MyAuthProvider>
                        </QueryClientProvider>
                    </MyI18nProvider>
                </MySnackbarProvider>
            </ThemeProvider>
        </CacheProvider>
    );
};

const AppRouter = () => {
    const {
        authState: [auth]
    } = useAuth();

    return (
        <RouterProvider
            router={createBrowserRouter(
                buildRoutes(filterRoutesWithPermissions(routes, auth.permissions))
            )}
        />
    );
};

const App = () => {
    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                refetchOnWindowFocus: true,
                refetchOnReconnect: true,
                retry: 0,
                staleTime: 5 * 60 * 1000
            }
        }
    });

    return (
        <AppDependencies testing={false} queryClient={queryClient}>
            <AppRouter />
        </AppDependencies>
    );
};

export default App;
