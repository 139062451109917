import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material';

type StylesProps = {
    addPaddingBottom: boolean;
};

export const useStyles = makeStyles<StylesProps>()((theme: Theme, { addPaddingBottom }) => ({
    containerLayout: {
        display: 'flex',
        paddingBottom: 0,
        minHeight: '100vh',
        [theme.breakpoints.down('sm')]: {
            paddingBottom: addPaddingBottom ? 80 : 0
        }
    },
    containerOutlet: {
        flexGrow: 1,
        overflowX: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        [theme.breakpoints.down('sm')]: {
            minHeight: 'calc(100vh - 80px)'
        },
        justifyContent: 'space-between'
    }
}));
