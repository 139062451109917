import { ReactElement } from 'react';
import { LoadingButton } from '@mui/lab';
import { useStyles } from './shared-button.style';

type SharedBtnProps = {
    onClick: () => void;
    title: string | ReactElement;
    startIcon?: ReactElement;
    endIcon?: ReactElement;
    variant?: 'contained' | 'outlined' | 'text';
    color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
    size?: 'small' | 'medium' | 'large';
    className?: string;
    disabled?: boolean;
    loading?: boolean;
    fullWidth?: boolean;
    href?: string;
    dataTestId?: string;
};

const SharedButton = (props: SharedBtnProps) => {
    const {
        title,
        onClick,
        startIcon,
        endIcon,
        variant,
        color,
        size,
        className,
        loading,
        fullWidth
    } = props;

    const { classes: styles, cx } = useStyles();

    return (
        <>
            <LoadingButton
                href={props.href}
                data-testid={props.dataTestId || 'shared-button'}
                loading={loading}
                disabled={props.disabled || false}
                className={cx(className, styles.button)}
                variant={variant || 'contained'}
                color={color || 'primary'}
                size={size || 'medium'}
                onClick={e => {
                    e.preventDefault();
                    onClick();
                }}
                startIcon={startIcon}
                fullWidth={fullWidth}
                endIcon={endIcon}
            >
                {title}
            </LoadingButton>
        </>
    );
};

export default SharedButton;
