import { PERMISSIONS } from 'framework/utils';

export function hasPermissions(permissions: number[]) {
    const tagView = () => {
        return permissions.includes(PERMISSIONS.DEVICE_MANAGEMENT_VIEW);
    };

    const tagCreate = () => {
        return permissions.includes(PERMISSIONS.DEVICE_MANAGEMENT_CREATE);
    };

    const tagEdit = () => {
        return permissions.includes(PERMISSIONS.DEVICE_MANAGEMENT_EDIT);
    };

    const tagDelete = () => {
        return permissions.includes(PERMISSIONS.DEVICE_MANAGEMENT_DELETE);
    };

    const userView = () => {
        return permissions.includes(PERMISSIONS.USER_MANAGEMENT_VIEW);
    };

    const userCreate = () => {
        return permissions.includes(PERMISSIONS.USER_MANAGEMENT_CREATE);
    };

    const userEdit = () => {
        return permissions.includes(PERMISSIONS.USER_MANAGEMENT_EDIT);
    };

    const userDelete = () => {
        return permissions.includes(PERMISSIONS.USER_MANAGEMENT_DELETE);
    };

    const deviceView = () => {
        return permissions.includes(PERMISSIONS.DEVICE_MANAGEMENT_VIEW);
    };

    const deviceCreate = () => {
        return permissions.includes(PERMISSIONS.DEVICE_MANAGEMENT_CREATE);
    };

    const deviceEdit = () => {
        return permissions.includes(PERMISSIONS.DEVICE_MANAGEMENT_EDIT);
    };

    const deviceDelete = () => {
        return permissions.includes(PERMISSIONS.DEVICE_MANAGEMENT_DELETE);
    };

    const alertView = () => {
        return permissions.includes(PERMISSIONS.ALERT_MANAGEMENT_VIEW);
    };

    const alertEdit = () => {
        return permissions.includes(PERMISSIONS.ALERT_MANAGEMENT_EDIT);
    };

    return {
        tagView,
        tagCreate,
        tagEdit,
        tagDelete,
        userView,
        userCreate,
        userEdit,
        userDelete,
        deviceView,
        deviceCreate,
        deviceEdit,
        deviceDelete,
        alertView,
        alertEdit
    };
}
