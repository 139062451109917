import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material';

export const useStyles = makeStyles()((theme: Theme) => ({
    footerContainer: {
        backgroundColor: theme.palette.common.grayF1,
        height: 53,
        marginLeft: -40,
        marginRight: -40,
        marginBottom: -10,
        [theme.breakpoints.down('sm')]: {
            marginLeft: -20,
            marginRight: -20
        },
        display: 'flex',
        justifyContent: 'center'
    },
    footerItems: {
        display: 'flex',
        gap: 40,
        alignItems: 'center'
    }
}));
