import { BottomNavigation, BottomNavigationAction, Paper, Typography } from '@mui/material';
import { useI18nService } from 'framework/i18n/i18n-service';
import { useStyles } from './app-bottom-navigation.style';
import { useLocation, useNavigate } from 'react-router-dom';
import { menuItemConfig, menuItems } from './utils/menu-items';
import { changeItemActive } from './utils/utils';
import { Me } from 'framework/api/models/user-models';

const getValueFromKey = (itemActive: string): number => {
    switch (itemActive) {
        case 'users':
            return 0;
        case 'devices':
            return 1;
        case 'alerts':
            return 2;
        case 'config':
            return 3;
        default:
            return 0;
    }
};

const getItemSelected = (pathname: string) => {
    const itemNavActive = changeItemActive(pathname);
    return getValueFromKey(itemNavActive);
};

type AppBottomNavigationProps = {
    unreadAlerts: number;
    auth: Me;
};

const AppBottomNavigation = (props: AppBottomNavigationProps) => {
    const { t } = useI18nService();
    const navigate = useNavigate();
    const { classes: styles } = useStyles();
    const location = useLocation();

    const onClick = (route: string) => {
        navigate(route);
    };

    return (
        <Paper
            data-testid={'bottomNavigation'}
            sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}
            className={styles.paperZIndex}
            elevation={3}
        >
            <BottomNavigation
                className={styles.appBottomNavigation}
                showLabels={true}
                value={getItemSelected(location.pathname)}
            >
                {menuItems
                    .filter(menu => props.auth.permissions.includes(menu.permission))
                    .map((item, i) => (
                        <BottomNavigationAction
                            classes={{
                                selected: styles.appBottomNavigationActionSelected
                            }}
                            data-testid={`list-item-navigation-${item.path}`}
                            className={styles.appBottomNavigationAction}
                            key={i}
                            label={
                                <Typography className={styles.label} variant={'description'}>
                                    {t(item.label)}
                                </Typography>
                            }
                            icon={item.icon}
                            onClick={() => onClick(item.path)}
                        />
                    ))}
                <BottomNavigationAction
                    classes={{
                        selected: styles.appBottomNavigationActionSelected
                    }}
                    data-testid={`list-item-navigation-${menuItemConfig.path}`}
                    className={styles.appBottomNavigationAction}
                    key={menuItemConfig.key}
                    label={
                        <Typography className={styles.label} variant={'description'}>
                            {t(menuItemConfig.label)}
                        </Typography>
                    }
                    icon={menuItemConfig.icon}
                    onClick={() => onClick(menuItemConfig.path)}
                />
            </BottomNavigation>
        </Paper>
    );
};

export default AppBottomNavigation;
