import { Outlet } from 'react-router-dom';
import { RoutesType } from './index';
import { RouteN } from '../../routes-path';
import { lazy } from 'react';
import { PERMISSIONS } from 'framework/utils';

const DevicesList = lazy(() => import('src/features/devices/framework/list/device-list'));
const DeviceCreate = lazy(() => import('src/features/devices/framework/create/device-create'));
const DeviceDetail = lazy(() => import('src/features/devices/framework/detail/device-detail'));
const ScriptCreate = lazy(
    () => import('src/features/devices/framework/scripts/create/script-create')
);
const ScriptEdit = lazy(() => import('src/features/devices/framework/scripts/edit/script-edit'));

export const DevicesRoutes: RoutesType[] = [
    {
        path: RouteN.devices.route,
        suspense: false,
        id: 'devices',
        permission: PERMISSIONS.DEVICE_MANAGEMENT_VIEW,
        element: <Outlet />,
        children: [
            {
                path: '',
                suspense: true,
                id: 'devices-list',
                element: <DevicesList />,
                permission: PERMISSIONS.DEVICE_MANAGEMENT_VIEW
            },
            {
                path: RouteN.devices.children.create,
                suspense: true,
                id: 'devices-create',
                element: <DeviceCreate />,
                permission: PERMISSIONS.DEVICE_MANAGEMENT_CREATE
            },
            {
                path: RouteN.devices.children.detail,
                suspense: true,
                id: 'devices-detail',
                element: <DeviceDetail />,
                permission: PERMISSIONS.DEVICE_MANAGEMENT_VIEW
            },
            {
                path: RouteN.devices.children.createScript,
                suspense: true,
                id: 'devices-script-create',
                element: <ScriptCreate />,
                permission: PERMISSIONS.DEVICE_MANAGEMENT_CREATE
            },
            {
                path: RouteN.devices.children.editScript,
                suspense: true,
                id: 'devices-script-edit',
                element: <ScriptEdit />,
                permission: PERMISSIONS.DEVICE_MANAGEMENT_VIEW
            }
        ]
    }
];
