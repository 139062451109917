export * from './users-navigate';
export * from './auth-navigate';
export * from './config-navigate';
export * from './devices-navigate';
export * from './terms-navigate';
export * from './alert-navigate';

export const goToPrincipal = () => {
    return '/';
};
