import React from 'react';
import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';
import AppDrawer from './components/navigation-menu/app-drawer';
import useMediaQuery from '@mui/material/useMediaQuery';
import AppBottomNavigation from './components/navigation-menu/app-bottom-navigation';
import { useStyles } from './app-layout.style';
import { Footer } from 'features/components/footer';
import useTheme from '@mui/system/useTheme';
import { useAuth } from 'features/auth/framework/provider/auth-provider';
import { useQueryGetUnreadAlerts } from 'framework/api/hooks/alert-hooks';

export const userAgentApp = new RegExp(/Chesnut@App/i);

export const AppLayout = () => {
    const theme = useTheme();
    const matchesUpMd = useMediaQuery(theme.breakpoints.up('md'));
    const isAppUserAgent = userAgentApp.test(window.navigator.userAgent);
    const { classes: styles } = useStyles({ addPaddingBottom: !isAppUserAgent });

    const {
        authState: [auth],
        hasPermissions
    } = useAuth();

    const { unread } = useQueryGetUnreadAlerts(hasPermissions.alertView());

    return (
        <Box data-testid={'app-layout'} className={styles.containerLayout}>
            {matchesUpMd && <AppDrawer unreadAlerts={unread} auth={auth} />}
            <Box
                component={'main'}
                pl={{ xs: 20, md: 32 }}
                pr={{ xs: 20 }}
                pb={10}
                className={styles.containerOutlet}
            >
                <Box pb={!isAppUserAgent ? 20 : 0}>
                    <Outlet />
                </Box>
                {!isAppUserAgent && <Footer />}
            </Box>
            {!matchesUpMd && !isAppUserAgent && (
                <AppBottomNavigation unreadAlerts={unread} auth={auth} />
            )}
        </Box>
    );
};

export default AppLayout;
