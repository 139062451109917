export const typographys = {
    h2: undefined,
    h3: undefined,
    h4: undefined,
    h5: undefined,
    h6: undefined,
    subtitle1: undefined,
    subtitle2: undefined,
    body1: undefined,
    body2: undefined,
    overline: undefined,
    h1: {
        fontFamily: 'Roboto',
        fontSize: 2.5,
        fontWeight: 'bold',
        lineHeight: '60px'
    },
    headerTitle: {
        fontFamily: 'Roboto',
        fontSize: 2,
        fontWeight: 'bold',
        lineHeight: '48px'
    },
    sectionTitle: {
        fontFamily: 'Roboto',
        fontSize: 1.5,
        fontWeight: 'bold',
        lineHeight: '32px'
    },
    description: {
        fontFamily: 'Roboto',
        fontSize: 1,
        fontWeight: 'normal',
        lineHeight: '24px'
    },
    headerTable: {
        fontFamily: 'Roboto',
        fontSize: 1,
        fontWeight: 'bold',
        lineHeight: '24px'
    },
    contentTable: {
        fontFamily: 'Roboto',
        fontSize: 1,
        fontWeight: 'normal',
        lineHeight: '24px'
    },
    deviceInfo: {
        fontFamily: 'Roboto',
        fontSize: 0.875,
        fontWeight: 'normal',
        lineHeight: '16px'
    },
    inputInactive: {
        fontFamily: 'Roboto',
        fontSize: 1,
        fontWeight: 400,
        lineHeight: '24px'
    },
    inputActiveFilled: {
        fontFamily: 'Roboto',
        fontSize: 1,
        fontWeight: 400,
        lineHeight: '24px'
    },
    body: {
        fontFamily: 'Roboto',
        fontSize: 0.875,
        fontWeight: 'normal'
    },
    button: {
        fontFamily: 'Roboto',
        fontSize: 1,
        fontWeight: 'bold'
    },
    caption: {
        fontFamily: 'Roboto',
        fontSize: 0.75,
        fontWeight: 'normal'
    }
};
