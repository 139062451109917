import { Fragment, ReactNode } from 'react';
import { Box, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { useStyles } from './item-navigation.style';

export type ItemNavigationProps = {
    title: string;
    icon: ReactNode;
    path: string;
    isActive: boolean;
    count: number;
};

const ItemNavigation = (props: ItemNavigationProps) => {
    const { title, icon, path, isActive } = props;
    const { classes: styles } = useStyles();

    return (
        <ListItemButton
            data-testid={`list-item-navigation-${path}`}
            selected={isActive}
            className={styles.itemButton}
            sx={{ paddingX: 12, paddingY: 12 }}
        >
            <ListItemIcon className={styles.listItemIcon}>
                <Box
                    data-testid={`box-icon-item-nav-${path}`}
                    className={styles.boxItemNavigationIcon}
                >
                    {icon}
                </Box>
            </ListItemIcon>

            <ListItemText
                primary={
                    <Fragment>
                        {!!props.count ? (
                            <Box className={styles.textWithCount}>
                                <Typography
                                    color={isActive ? 'primary' : 'common.black'}
                                    variant={'description'}
                                >
                                    {title}
                                </Typography>
                                <Box data-testid={'unread-alerts-badge'} className={styles.circle}>
                                    <Typography variant={'body'} color={'common.white'}>
                                        {props.count > 99 ? '+99' : props.count}
                                    </Typography>
                                </Box>
                            </Box>
                        ) : (
                            <Typography
                                color={isActive ? 'primary' : 'common.black'}
                                variant={'description'}
                            >
                                {title}
                            </Typography>
                        )}
                    </Fragment>
                }
            />
        </ListItemButton>
    );
};

export default ItemNavigation;
