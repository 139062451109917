export const isValidateEmail = (email: string) => {
    const emailPattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailPattern.test(email);
};

export const isValidatePassword = (password: string) => {
    const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[@$%#]).{8,}$/;
    return passwordPattern.test(password);
};

export const containSpecialCharacter = (word: string) => {
    const pattern = /^[$&+,:;=?@#|'<>.^*()%!-]/;
    return pattern.test(word);
};

export const isACoordinate = (coordinates: string) => {
    const pattern = /^-?\d{1,3}\.\d+,\s*(-?\d{1,3}\.\d+)$/;
    return pattern.test(coordinates);
};
