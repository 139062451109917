import { RoutesType } from './index';
import { RouteN } from '../../routes-path';
import { lazy } from 'react';
import { PERMISSIONS } from 'framework/utils';
import { Outlet } from 'react-router-dom';

const UserList = lazy(() => import('src/features/user/framework/list/user-list'));
const UserCreate = lazy(() => import('src/features/user/framework/create/user-create'));
const UserEdit = lazy(() => import('src/features/user/framework/edit/user-edit'));

export const UserRoutes: RoutesType[] = [
    {
        path: RouteN.users.route,
        suspense: false,
        id: 'users-list',
        element: <Outlet />,
        permission: PERMISSIONS.USER_MANAGEMENT_VIEW,
        children: [
            {
                path: '',
                suspense: true,
                id: 'users-0',
                element: <UserList />,
                permission: PERMISSIONS.USER_MANAGEMENT_VIEW
            },
            {
                path: RouteN.users.children.create,
                suspense: true,
                id: 'users-1',
                element: <UserCreate />,
                permission: PERMISSIONS.USER_MANAGEMENT_CREATE
            },
            {
                path: RouteN.users.children.edit,
                suspense: true,
                id: 'users-2',
                element: <UserEdit />,
                permission: PERMISSIONS.USER_MANAGEMENT_VIEW
            }
        ]
    }
];
