import { MyApiRequest } from 'framework/api/my-api';
import { validateGetAutocomplete, validateGetPlaceDetails } from 'framework/api/validations/gmaps';
import { LatLng, PredictionGMaps } from 'framework/api/models/gmaps-models';

const GMAPS_KEY_QUERY_GEOCODE_DIRECTION = 'geocodeOrDirection=';
const GMAPS_KEY_QUERY_PLACE_ID = 'place_id=';
const GMAPS_AUTOCOMPLETE = 'autocomplete';
const GMAPS_DETAIL = 'detail';
const GMAPS = 'gmaps';

export function getGMaps(request: MyApiRequest) {
    async function getAutocomplete(geocodeOrDirection: string): Promise<PredictionGMaps[]> {
        const response = await request(
            GMAPS +
                '/' +
                GMAPS_AUTOCOMPLETE +
                '?' +
                GMAPS_KEY_QUERY_GEOCODE_DIRECTION +
                geocodeOrDirection,
            'GET'
        );

        const data = await response.json();

        return validateGetAutocomplete(data);
    }

    async function getPlaceDetails(placeId: string): Promise<LatLng> {
        const response = await request(
            GMAPS + '/' + GMAPS_DETAIL + '?' + GMAPS_KEY_QUERY_PLACE_ID + placeId,
            'GET'
        );

        const data = await response.json();

        return validateGetPlaceDetails(data);
    }

    return {
        getAutocomplete,
        getPlaceDetails
    };
}
