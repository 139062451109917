import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material';

export const useStyles = makeStyles()((theme: Theme) => ({
    snackbarBottom: {
        marginBottom: 0,
        [theme.breakpoints.down('lg')]: {
            marginBottom: 80
        }
    },
    widthChange: {
        [theme.breakpoints.down('lg')]: {
            minWidth: '0px !important',
            '> .notistack-MuiContent': {
                minWidth: '0px !important'
            }
        }
    }
}));
