import { createContext, FC, useCallback, useContext } from 'react';
import { SnackbarProvider, useSnackbar } from 'notistack';
import { useStyles } from './notifications.style';

type NotificationsOptions = {
    variant?: 'default' | 'error' | 'success' | 'warning' | 'info';
    key?: number | string;
    preventDuplicate?: boolean;
};

export type NotificationsService = (
    message: string,
    options?: NotificationsOptions
) => string | number;

const defaultNotificationsProvider: NotificationsService = (
    message: string,
    options?: NotificationsOptions
) => '';

const NotificationsContext = createContext<NotificationsService>(defaultNotificationsProvider);

export const MySnackbarProvider: FC<React.PropsWithChildren<{}>> = props => {
    const { classes: styles } = useStyles();

    return (
        <SnackbarProvider
            classes={{ containerRoot: styles.snackbarBottom, root: styles.widthChange }}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
            }}
        >
            <NotificationsProvider {...props} />
        </SnackbarProvider>
    );
};

const NotificationsProvider: FC<React.PropsWithChildren<{}>> = props => {
    const { enqueueSnackbar } = useSnackbar();

    const notification = useCallback(
        (message: string, options?: NotificationsOptions): string | number =>
            enqueueSnackbar(message, options),
        [enqueueSnackbar]
    );

    return <NotificationsContext.Provider value={notification} {...props} />;
};

export const useNotificationService = () => {
    return useContext(NotificationsContext);
};
