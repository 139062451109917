import { ReactElement, Suspense } from 'react';
import { Navigate, RouteObject } from 'react-router-dom';
import { RoutesType } from './routes';
import { LinearProgress } from '@mui/material';
import {
    goToAlertList,
    goToConfig,
    goToDevicesList,
    goToUsersList
} from 'routing/framework/navigates';
import { PERMISSIONS } from 'framework/utils';
import { userAgentApp } from 'framework/web/layout/app-layout';

const getRouteIndexNavigateWithPermissions = (permissions: number[]) => {
    const isAppUserAgent = userAgentApp.test(window.navigator.userAgent);
    if (isAppUserAgent) {
        if (permissions.includes(PERMISSIONS.DEVICE_MANAGEMENT_VIEW)) {
            return goToDevicesList();
        } else if (permissions.includes(PERMISSIONS.ALERT_MANAGEMENT_VIEW)) {
            return goToAlertList();
        }
    } else {
        if (permissions.includes(PERMISSIONS.USER_MANAGEMENT_VIEW)) {
            return goToUsersList();
        } else if (permissions.includes(PERMISSIONS.DEVICE_MANAGEMENT_VIEW)) {
            return goToDevicesList();
        } else if (permissions.includes(PERMISSIONS.ALERT_MANAGEMENT_VIEW)) {
            return goToAlertList();
        }
    }

    return goToConfig();
};

export const filterRoutesWithPermissions = (
    routes: RoutesType[],
    permissions: number[]
): RoutesType[] => {
    if (!permissions.length) return routes;

    return routes
        .filter(r => {
            if (!r.permission) return true;
            return permissions.includes(r.permission);
        })
        .map(r => {
            if (r.id === 'route-index') {
                r.element = <Navigate to={getRouteIndexNavigateWithPermissions(permissions)} />;
            }
            if (!r.children) return r;

            return {
                ...r,
                children: filterRoutesWithPermissions(r.children, permissions)
            };
        });
};

export const buildRoutes = (routes: RoutesType[]): RouteObject[] => {
    return routes.map(r => {
        if (!r.children) {
            return {
                path: r.path,
                index: true,
                id: r.id,
                element: addSuspenseIfNecessary(r.element, r.suspense)
            };
        }
        return {
            path: r.path,
            index: false,
            id: r.id,
            element: addSuspenseIfNecessary(r.element, r.suspense),
            children: buildRoutes(r.children)
        };
    });
};

const addSuspenseIfNecessary = (children: ReactElement, suspenseNecessary: boolean) => {
    if (suspenseNecessary) {
        return (
            <Suspense
                fallback={
                    <LinearProgress sx={{ position: 'absolute', top: 0, right: 0, left: 0 }} />
                }
            >
                {children}
            </Suspense>
        );
    }

    return children;
};
