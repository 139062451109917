import { ItemList } from 'framework/api/models';
import { User } from 'framework/api/models/user-models';
import { validateUser } from './get-user';

export const validateGetUsers = (usersResponseDTO: any): ItemList<User> => {
    return {
        items: 'items' in usersResponseDTO ? validateUsers(usersResponseDTO.items) : [],
        count: 'count' in usersResponseDTO ? usersResponseDTO.count || 0 : 0
    };
};

const validateUsers = (usersDTO: any[]): User[] => {
    const users: User[] = [];

    usersDTO.forEach((userDTO: any) => {
        const user = validateUser(userDTO);
        if (user) {
            users.push(user);
        }
    });

    return users;
};
