import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material';

export const useStyles = makeStyles()((theme: Theme) => ({
    itemButton: {
        height: 48,
        '&.MuiListItemButton-root.Mui-selected': {
            backgroundColor: theme.palette.secondary.main + '05',
            borderRight: `4px solid ${theme.palette.secondary.main}`
        }
    },
    boxItemNavigationIcon: {
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    listItemIcon: {
        minWidth: 38
    },
    textWithCount: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    circle: {
        backgroundColor: theme.palette.common.red,
        width: 24,
        height: 24,
        borderRadius: 100,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
}));
